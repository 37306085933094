import React, { useEffect } from 'react';

import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import FormGroup from "react-bootstrap/lib/FormGroup";
import ControlLabel from "react-bootstrap/lib/ControlLabel";
import FormControl from "react-bootstrap/lib/FormControl";

import Card from 'components/Card/Card';

import Button from 'components/CustomButton/CustomButton';
import logo from 'assets/brand/icon-white.svg';
import authService from 'services/authService';
import { validate } from 'indicative/validator';
import { Link } from 'react-router-dom';

function ForgotPassword() {
  const [pageState, setPageState] = React.useState({
    buttonDisabled: false,
    cardHidden: true,
    errorMsg: '',
    email: '',
    successMsg: '',
  });

  const handleChange = (e) => {
    setPageState({
      ...pageState,
      [e.target.name]: e.target.value,
    });
  };

  const handleSend = async (e) => {
    e.preventDefault();

    const rules = {
      email: 'required|email',
    };

    const messages = {
      required: (field) => `${field.charAt(0).toUpperCase() + field.slice(1)} is required`,
      'email.email': 'Please enter a valid email address',
    };

    const payload = {
      email: pageState.email,
    };

    try {
      await validate(payload, rules, messages);
    } catch (err) {
      setPageState({ ...pageState, errorMsg: err[0].message, successMsg: '' });
      return;
    }

    try {
      const response = await authService.forgotPassword(pageState.email);
      if (response.success) {
        setPageState({
          ...pageState,
          errorMsg: '',
          successMsg: `Password reset email sent to ${pageState.email}.`,
          buttonDisabled: true,
        });
      } else setPageState({ ...pageState, successMsg: '', errorMsg: response.msg });
    } catch (err) {
      setPageState({
        ...pageState,
        successMsg: '',
        errorMsg: 'Failed to send email.',
      });
    }
  };

  useEffect(() => {
    let mounted = true;
    setTimeout(() => {
      if (mounted) setPageState({ ...pageState, cardHidden: false });
    }, 700);
    return () => (mounted = false);
  }, []);

  return (
    <>
      <Grid>
        <Row>
          <Col md={4} sm={6} mdOffset={4} smOffset={3}>
            <form onSubmit={handleSend}>
              <Card
                hidden={pageState.cardHidden}
                content={(
                  <div>
                    <div className="login-icon">
                      <img src={logo} alt="react-logo" />
                    </div>
                    <FormGroup>
                      {pageState.errorMsg && (
                        <div
                          className="alert alert-danger mt-2"
                          style={{
                            display: pageState.errorMsg ? 'block' : 'none',
                          }}
                          role="alert"
                        >
                          {' '}
                          {pageState.errorMsg}
                        </div>
                      )}

                      {pageState.successMsg && (
                        <div
                          className="alert alert-success mt-2"
                          style={{
                            display: pageState.successMsg ? 'block' : 'none',
                          }}
                          role="alert"
                        >
                          {' '}
                          {pageState.successMsg}
                        </div>
                      )}

                      <ControlLabel>Email address</ControlLabel>
                      <FormControl
                        placeholder="Enter email"
                        type="email"
                        name="email"
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <div>
                      <Link to="/auth/login">Back to Login?</Link>
                    </div>
                  </div>
                )}
                legend={(
                  <Button
                    bsStyle="primary"
                    disabled={pageState.buttonDisabled}
                    fill
                    wd
                    type="submit"
                  >
                    Send
                  </Button>
                )}
                ftTextCenter
              />
            </form>
          </Col>
        </Row>
      </Grid>
    </>
  );
}

export default ForgotPassword;
