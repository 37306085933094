import { instance as axios } from "./http-common";

export default {
  getUsers,
  getUser,
  updateUser,
  addUser,
  getCurrentUser,
  getVendorAssociates,
  updateSelfUser,
};

async function getUsers(size, page, sortBy, filters) {

  if (sortBy && sortBy.length > 0) {
    var order = sortBy[0].desc ? 'desc' : 'asc';
    sortBy = sortBy[0].id;
  }

  let params = {
    size,
    page,
    sortBy,
    order,
  }

  if (filters) {
    filters.forEach(function (v) {
      params[v.id] = v.value;
    })
  }

  let config = {
    withCredentials: true,
    params
  };
  try {
    var res = await axios.get(
      `users`,
      config
    );
    if (res.status === 200 && res.data.success === true)
      return { success: true, users: res.data.users, count: res.data.count };
    else return { success: false, msg: res.error.message };
  } catch (e) {
    console.log(e.response);
    return { success: false, msg: "Unable to fetch users." };
  }
}

async function getUser(id) {
  let res;
  let config = {};
  try {
    res = await axios.get(
      `users/${id}`,
      config
    );
    if (res.status === 200 && res.data.success === true)
      return { success: true, user: res.data.user };
    else return { success: false, msg: res.error.message };
  } catch (e) {
    console.log(e.response);
    return { success: false, msg: "Unable to fetch user." };

  }
}

async function getVendorAssociates() {
  let res;
  let config = {};
  try {
    res = await axios.get(
      `users/associates`,
      config
    );
    if (res.status === 200 && res.data.success === true)
      return { success: true, users: res.data.users };
    else return { success: false, msg: res.error.message };
  } catch (e) {
    return { success: false, msg: "Unable to fetch associates." };

  }
}
async function getCurrentUser() {
  let res;
  let config = {};
  try {
    res = await axios.get(
      `users/self`,
      config
    );
    if (res.status === 200 && res.data.success === true)
      return { success: true, user: res.data.user };
    else return { success: false, msg: res.error.message };
  } catch (e) {
    console.log(e.response);
    return { success: false, msg: "Unable to fetch user." };

  }
}
async function addUser(params) {
  let res;
  let config = {};
  try {
    res = await axios.post(
      `users`,
      params,
      config
    );
    if (res.status === 200 && res.data.success === true)
      return { success: true };
    else return { success: false, msg: res.data.message };
  } catch (e) {
    console.log(e.response);
    return { success: false, msg: "Unable to add user." };

  }
}

async function updateUser(id, params) {
  let res;
  let config = {};
  try {
    res = await axios.post(
      `users/vendor/${id}`,
      params,
      config
    );
    if (res.status === 200 && res.data.success === true)
      return { success: true };
    else return { success: false, msg: res.error.message };
  } catch (e) {
    console.log(e.response);
    return { success: false, msg: "Unable to update user." };
  }
}
async function updateSelfUser(id, params) {
  let res;
  let config = {};
  try {
    res = await axios.post(
      `users/self`,
      params,
      config
    );
    if (res.status === 200 && res.data.success === true) return { success: true };
    else return { success: false, msg: res.error.message };
  } catch (e) {
    console.log(e.response);
    if (e.response.data.error.message) {
      return { success: false, msg: e.response.data.error.message };
    }
    else {
      return { success: false, msg: "Unable to update profile." };
    }
  }
}
