import React, { useContext } from 'react';
import { authContext } from 'state/AuthProvider';
import AuthLayout from 'layouts/Auth';
import AdminLayout from 'layouts/Admin';
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { can_navigate } from 'components/Can.jsx';

const ProtectedRoute = ({ loggedIn, ...props }) => (loggedIn ? <Route component={props.component} path={props.path} /> : <Redirect to="/auth/login" />);

const PublicRoute = ({ allowed, loggedIn, ...props }) => (loggedIn ? (allowed ? <Redirect to="/admin/users" /> : <Redirect to="/admin/orders" />) : <Route component={props.component} path={props.path} />);

function Routing() {
  const { ...auth } = useContext(authContext);

  return (
    <>
      <BrowserRouter>
        <Switch>
          <ProtectedRoute
            loggedIn={auth.auth.loggedIn}
            path="/admin"
            component={AdminLayout}
          />
          <PublicRoute
            allowed={can_navigate("Users")}
            loggedIn={auth.auth.loggedIn}
            path="/auth"
            component={AuthLayout}
          />
          {can_navigate("Users") ? <Redirect exact from="/" to="/admin/users" />
            : <Redirect exact from="/" to="/admin/orders" />}
          <Route path="*" component={() => '404 Not Found'} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default Routing;
