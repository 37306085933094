import { instance as axios } from "./http-common";

export default {
  getAudit,
  updateScan,
};
async function getAudit(scanId) {
  let res;
  let config = {};
  try {
    res = await axios.get(
      `scans/${scanId}/audit`,
      config
    );
    if (res.status === 200 && res.data.success === true)
      return { success: true, audit: res.data.audit };
    else return { success: false, msg: res.error.message };
  } catch (e) {
    console.log(e.response);
    return { success: false, msg: "Unable to fetch audit data." };
  }
}
async function updateScan(scanId, scanData) {
  let res;
  let config = {};
  try {
    res = await axios.post(
      `scans/${scanId}/`,
      scanData,
      config
    );
    if (res.status === 200 && res.data.success === true)
      return { success: true };
    else return { success: false, msg: res.error.message };
  } catch (e) {
    console.log(e.response);
    return { success: false, msg: "Unable to update scan." };
  }
}