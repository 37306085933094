import { instance as axios } from "./http-common";

export default {
  accessories,
  addAccessories,
  addAccessory,
  batchAssign,
  deleteAccessory,
};

async function accessories(size, page, sortBy, filters) {
  if (sortBy && sortBy.length > 0) {
    var order = sortBy[0].desc ? 'desc' : 'asc';
    sortBy = sortBy[0].id;
  }

  let params = {
    size,
    page,
    sortBy,
    order,
  }

  if (filters) {
    filters.forEach(function (v) {
      params[v.id] = v.value;
    })
  }


  let config = {
    params
  };
  try {
    var res = await axios.get(
      `devices`,
      config
    );

    if (res.status === 200 && res.data.success === true)
      return { success: true, devices: res.data.devices, count: res.data.count };
    else return { success: false, msg: res.error.message };
  } catch (e) {
    console.log(e.response);
    if (e.response.data) {
      return { success: false, msg: e.response.data.message };
    }
    return { success: false, msg: "Unable to fetch device." };
  }
}


async function addAccessory(deviceData) {
  let res;
  let config = {};
  try {
    res = await axios.post(
      `devices`, deviceData, config
    );
    if (res.status === 200 && res.data.success === true)
      return { success: true };
    else return { success: false, msg: res.error.message };
  } catch (e) {
    console.log(e.response);
    if (e.response.data) {
      return { success: false, msg: e.response.data.error.message };
    }
    return { success: false, msg: "Unable to add device." };
  }
}

async function addAccessories(devices) {
  let res;
  let config = {};
  try {
    res = await axios.post(
      `devices/batch`, { devices },
      config
    );
    if (res.status === 200 && res.data.success === true)
      return { success: true };
    else return { success: false, msg: res.error.message };
  } catch (e) {
    console.log(e.response);
    if (e.response.data) {
      return { success: false, msg: e.response.data.error.message };
    }
    return { success: false, msg: "Unable to add devices." };
  }
}

async function batchAssign(count, vendorId) {
  let res;
  let config = {};
  try {
    res = await axios.post(
      `devices/batch/assign`, { count: parseInt(count, 10), vendorId },
      config
    );
    if (res.status === 200 && res.data.success === true)
      return { success: true };
    else return { success: false, msg: res.error.message };
  } catch (e) {
    console.log(e.response);
    if (e.response.data) {
      return { success: false, msg: e.response.data.error.message };
    }
    return { success: false, msg: "Unable to assign devices." };
  }
}
async function deleteAccessory(serialNumber) {
  let res;
  let config = {};
  try {
    res = await axios.delete(
      `devices/${serialNumber}`,
      config
    );
    if (res.status === 200 && res.data.success === true)
      return { success: true };
    else return { success: false, msg: res.error.message };
  } catch (e) {
    console.log(e.response);
    if (e.response.data) {
      return { success: false, msg: e.response.data.error.message };
    }
    return { success: false, msg: "Unable to delete device." };
  }
}
