import React from "react";

import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import Modal from "react-bootstrap/lib/Modal";
import Form from "react-bootstrap/lib/Form";
import FormGroup from "react-bootstrap/lib/FormGroup";
import ControlLabel from "react-bootstrap/lib/ControlLabel";
import FormControl from "react-bootstrap/lib/FormControl";
import ButtonToolbar from "react-bootstrap/lib/ButtonToolbar";

import Card from "components/Card/Card.jsx";
import FormInputs from "components/FormInputs/FormInputs.jsx";
import { validate } from 'indicative/validator';
import Button from "components/CustomButton/CustomButton.jsx";
import userService from 'services/userService';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import { useEffect } from "react";
import { useHistory } from "react-router";

function AdminPage(props) {
  const [creds, setCreds] = React.useState({
    id: "",
    name: "",
    email: "",
    phoneNumber: "",
    dob: "",
    address: "",
    role: "",
  }

  );
  const history = useHistory();
  const [msg, setMsg] = React.useState({ "success": "", "invalid": "", "buttonDisabled": true });
  const [validatorMessage, setValidatorMessage] = React.useState(null);
  const [passwordvalidatorMessage, setPasswordValidatorMessage] = React.useState(null);
  const [passwordmsg, setPasswordMsg] = React.useState({ "success": "", "invalid": "" });
  const [passwordCreds, setpasswordCreds] = React.useState({});
  const [showPasswordChange, setShowPasswordChange] = React.useState(false);
  useEffect(() => {
    userService.getCurrentUser().then((res) => {
      if (res.success) {
        let data = res.user;
        let date = new Date(res.user.dob);
        setCreds({
          ...creds,
          id: data.id,
          name: data.name,
          email: data.email,
          phoneNumber: data.phoneNumber,
          dob: date.toISOString().slice(0, 10),
          address: data.address,
          role: data.role,
        });
      }
      else {
        setMsg({ ...msg, success: "", invalid: "Couldn't load user data" });
        setValidatorMessage("");
      }
    })
  }, []);

  const handleChange = e => {
    setCreds({
      ...creds,
      [e.target.name]: e.target.value,
    });
  };
  const handlePasswordChange = e => {
    setPasswordMsg({ ...msg, success: '', invalid: '' });
    setpasswordCreds({ ...passwordCreds, [e.target.name]: e.target.value });
  }
  const handleRoleChange = (e) => {
    setCreds({ ...creds, role: e.target.value });
  };
  const handlePhoneChange = e => {
    setCreds({ ...creds, phoneNumber: e });
  }
  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    const rules = {
      oldPassword: 'required',
      password: 'required|min:8|max:25|confirmed|string',
    };
    const messages = {
      required: (field) => `${field.charAt(0).toUpperCase() + field.slice(1).replace(/([a-z])([A-Z-_])/g, '$1 $2')} is required`,
      'password.min': 'Please enter password upto 8 characters long',
      'password.confirmed': 'Passwords do not match',
    };
    try {
      const resp = await validate(passwordCreds, rules, messages);
      let params = {
        password: passwordCreds.oldPassword,
        new_password: passwordCreds.password,
        confirm_password: passwordCreds.password_confirmation,
      }
      userService.updateSelfUser(creds.id, params).then((res) => {
        if (res.success === true) {
          setPasswordValidatorMessage(null);
          setPasswordMsg({ ...msg, success: "Profile Updated Successfully!", invalid: "" });
          setTimeout(() => setShowPasswordChange(false), 3000);

        }
        else {
          setPasswordValidatorMessage(null);
          setPasswordMsg({ ...msg, success: "", invalid: res.msg });
        }
      })
    }
    catch (e) {
      console.log(e);
      setPasswordMsg({ ...msg, invalid: "", success: "" });
      setPasswordValidatorMessage(e[0].message);
    }

  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    const rules = {
      name: 'required',
      address: 'required',
      phoneNumber: 'required',
      dob: 'required'
    };
    const messages = {
      required: (field) => `${field.charAt(0).toUpperCase() + field.slice(1).replace(/([a-z])([A-Z-_])/g, '$1 $2')} is required`,
    };
    const error = [{ message: "Please enter a valid phone number" }];
    try {
      const resp = await validate(creds, rules, messages);
      const isValidNumber = isPossiblePhoneNumber(creds.phoneNumber);
      if (!isValidNumber) {
        throw error;
      }
      let params = {
        name: creds.name,
        email: creds.email,
        phoneNumber: creds.phoneNumber,
        address: creds.address,
        dob: creds.dob,
      };
      let res = await userService.updateUser(creds.id, params);
      if (res.success) {
        setValidatorMessage(null);
        setMsg({ ...msg, success: "Profile Updated Successfully!", invalid: "" });
        if (creds.role === "SuperAdmin") {
          setTimeout(() => history.push("/admin/users"), 3000);
        }
        else {
          setTimeout(() => history.push("/admin/vendor"), 3000);
        }
      }
      else {
        setValidatorMessage(null);
        setMsg({ ...msg, success: "", invalid: "Profile was not updated!" });
      }
    } catch (e) {
      console.log(e);
      setMsg({ ...msg, invalid: "", success: "" });
      setValidatorMessage(e[0].message);
    }

  };

  return (
    <div className="main-content" >
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title="Edit Admin Profile"
              content={
                <form>
                  <div>
                    {validatorMessage && (
                      <div
                        className="alert alert-danger mt-2"
                        style={{
                          display: validatorMessage ? 'block' : 'none',
                        }}
                        role="alert"
                      >
                        {' '}
                        {validatorMessage}
                      </div>
                    )}
                  </div>
                  <div>
                    {msg.invalid && (
                      <div
                        className="alert alert-danger mt-2"
                        style={{
                          display: msg.invalid ? 'block' : 'none',
                        }}
                        role="alert"
                      >
                        {' '}
                        {msg.invalid}
                      </div>
                    )}
                  </div>
                  <div>
                    {msg.success && (
                      <div
                        className="alert alert-success mt-2"
                        style={{
                          display: msg.success ? 'block' : 'none',
                        }}
                        role="alert"
                      >
                        {' '}
                        {msg.success}
                      </div>
                    )}
                  </div>
                  <FormInputs
                    ncols={["col-md-6", "col-md-6"]}
                    proprieties={[
                      {
                        label: "Company",
                        name: "company",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Company",
                        defaultValue: "DentSkan.",
                        disabled: true,
                      },
                      {
                        label: "Email address",
                        name: "email",
                        type: "email",
                        bsClass: "form-control",
                        defaultValue: creds.email,
                        disabled: true,
                      },
                    ]} />
                  <div className="row">
                    <div className="col-md-6">
                      <FormGroup controlId="formControlsSelect">
                        <ControlLabel>Role</ControlLabel>
                        <FormControl componentClass="select" disabled onChange={handleRoleChange} value={creds.role} placeholder="Role">
                          <option value="Vendor Admin">Admin</option>
                          <option value="Vendor Associate">Associate</option>
                        </FormControl>
                      </FormGroup>
                    </div>
                  </div>
                  <FormInputs
                    ncols={["col-md-6", "col-md-6"]}
                    proprieties={[
                      {
                        label: "Name",
                        type: "text",
                        name: "name",
                        bsClass: "form-control",
                        placeholder: "Name",
                        value: creds.name,
                        onChange: (e) => handleChange(e)
                      },
                      {
                        label: "Date of Birth",
                        type: "date",
                        name: "dob",
                        bsClass: "form-control",
                        value: creds.dob,
                        onChange: (e) => handleChange(e)
                      },
                    ]}
                  />
                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        label: "Adress",
                        type: "text",
                        name: "address",
                        bsClass: "form-control",
                        placeholder: "Home Adress",
                        value: creds.address,
                        onChange: (e) => handleChange(e)
                      },
                    ]}
                  />
                  <FormInputs
                    ncols={["col-md-4"]}
                    proprieties={[
                      {
                        label: "Phone Number",
                        componentClass: PhoneInput,
                        name: "phoneNumber",
                        value: creds.phoneNumber,
                        onChange: (e) => handlePhoneChange(e)
                      },
                    ]}></FormInputs>
                  <ButtonToolbar>
                    <Button
                      onClick={(e) => {
                        setPasswordMsg({ ...passwordmsg, success: '', invalid: '' });
                        setPasswordValidatorMessage(null);
                        setShowPasswordChange(true);

                      }}
                      bsStyle="default"
                      pullRight
                    >
                      Change Password
                    </Button>

                    <Button bsStyle="primary" pullRight fill type="submit" onClick={handleSubmit}>
                      Update Profile
                    </Button>
                  </ButtonToolbar>

                  <div className="clearfix" />
                </form>
              }
            />
          </Col>
        </Row>
      </Grid>
      <Modal show={showPasswordChange} onHide={() => setShowPasswordChange(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form horizontal>
            <div>
              {passwordvalidatorMessage && (
                <div
                  className="alert alert-danger mt-2"
                  style={{
                    display: passwordvalidatorMessage ? 'block' : 'none',
                  }}
                  role="alert"
                >
                  {' '}
                  {passwordvalidatorMessage}
                </div>
              )}
            </div>
            <div>
              {passwordmsg.invalid && (
                <div
                  className="alert alert-danger mt-2"
                  style={{
                    display: passwordmsg.invalid ? 'block' : 'none',
                  }}
                  role="alert"
                >
                  {' '}
                  {passwordmsg.invalid}
                </div>
              )}
            </div>
            <div>
              {passwordmsg.success && (
                <div
                  className="alert alert-success mt-2"
                  style={{
                    display: passwordmsg.success ? 'block' : 'none',
                  }}
                  role="alert"
                >
                  {' '}
                  {passwordmsg.success}
                </div>
              )}
            </div>
            <FormGroup controlId="formHorizontalOldPassword">
              <Col componentClass={ControlLabel} sm={3}>
                Old Password
              </Col>
              <Col sm={9}>
                <FormControl type="password" name="oldPassword" onChange={handlePasswordChange} placeholder="Password" />
              </Col>
            </FormGroup>
            <FormGroup controlId="formHorizontalNewPassword">
              <Col componentClass={ControlLabel} sm={3}>
                New Password
              </Col><Col sm={9}>
                <FormControl type="password" name="password" onChange={handlePasswordChange} placeholder="New Password" />
              </Col>
            </FormGroup>
            <FormGroup controlId="formHorizontalConfirmPassword">
              <Col componentClass={ControlLabel} sm={3}>
                Confirm Password
              </Col><Col sm={9}>
                <FormControl type="password" name="password_confirmation" onChange={handlePasswordChange} placeholder="Confirm Password" />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col smOffset={3} sm={9}>
                <Button type="submit" bsStyle="info" fill onClick={handlePasswordSubmit}>Change Password</Button>
              </Col>
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="default" onClick={() => { setShowPasswordChange(false); setPasswordMsg({ success: "", invalid: "" }); setPasswordValidatorMessage(null); }}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div >
  );
}


export default AdminPage;
