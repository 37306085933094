import React, { useState, useEffect } from "react";
import { STLViewer } from 'react-stl-obj-viewer';
import sessionService from 'services/sessionService';
import userService from 'services/userService';
import scanService from 'services/scanService';
import Select from 'react-select';
import { scanStatuses } from 'variables/Variables.jsx';
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import FormGroup from "react-bootstrap/lib/FormGroup";
import Form from "react-bootstrap/lib/Form";
import FormControl from "react-bootstrap/lib/FormControl";
import Glyphicon from "react-bootstrap/lib/Glyphicon";
//import { toast } from 'react-toastify';

import moment from 'moment';
import ReactLoading from 'react-loading';

import Button from "components/CustomButton/CustomButton.jsx";
import Card from "components/Card/Card.jsx";
import userProfilePic from "assets/brand/Icon.png";
import commentService from 'services/commentService';


export default function Scan(props) {
  /*const [lower, setLower] = React.useState();
  const [upper, setUpper] = React.useState();*/
  const [data, setData] = React.useState({});
  const [loading, setLoading] = useState(false);
  const [auditloading, setauditLoading] = useState(false);
  const [vendorUsers, setVendorUsers] = React.useState([]);
  const [assignedTo, setAssignedTo] = React.useState({ name: '', id: '' });
  const [status, setStatus] = React.useState();
  const [comment, setComment] = React.useState('');
  const [auditData, setAuditData] = React.useState([]);
  const [disableButton, setDisableButton] = useState(true);
  const [msg, setMsg] = React.useState({ "success": "", "invalid": "" });
  const [scanCreds, setscanCreds] = useState({
    status: "",
    assignedToId: null,
  });
  const handleCommentDelete = (e, id) => {
    e.preventDefault();
    commentService.deleteComment(id).then((res) => {
      if (res.success === true) {
        setMsg({ ...msg, success: "Comment has been deleted", invalid: "" });
        setauditLoading(true);
        setTimeout(() => fetchAudits(), 300);
      }
      else {
        setMsg({ ...msg, success: "", invalid: "Comment was not deleted" });
      }
    })
  }
  function fetchAudits() {
    scanService.getAudit(props.match.params.scanId).then((res) => {
      setauditLoading(false);
      let audit = [];
      const isKeyExist = (audit, key) => audit.find(x => Object.keys(x)[0] === key) ? true : false;
      const appendData = (audit, key, prop) => (audit.find(x => Object.keys(x)[0] === key) || {})[key].push(prop);
      let name = '';
      res.audit.map((prop) => {
        if (prop.updatedByName) {
          name = prop.updatedByName;
        }
        else {
          name = prop.user.name;
        }
        if (isKeyExist(audit, name)) {
          appendData(audit, name, prop);
        }
        else {
          let updates = [];
          updates.push(prop);
          let item = { [name]: updates };
          audit.push(item);
        }
      })
      setAuditData(audit);
      setMsg({ ...msg, success: "", invalid: res.msg });
      setauditLoading(false);
    })
  }
  function fetchScan() {
    sessionService.getSession(props.match.params.sessionId).then((res) => {
      setLoading(false);
      let i = res.session.scans.length;
      let scan;
      while (i--) {
        if (res.session.scans[i].id == props.match.params.scanId) {
          scan = res.session.scans[i];
        }
      }
      res.session.scan = scan;

      if (res.session.assignedTo) {
        setAssignedTo({ name: res.session.assignedTo.name, id: res.session.assignedTo.id });
      }

      setStatus(res.session.scan.status);
      setData(res.session);
    })
  }
  useEffect(() => {
    setLoading(true);
    fetchScan();
    userService.getUsers().then((res) => {
      if (res.success) {
        let users = res.users.filter(option => option.role in ["Vendor Admin", "Vendor Associate"]);
        users = users.map((user) => { return { value: user.id, label: user.name } });
        setVendorUsers(users);
      }
    })
    /*sessionService.getScanURL(props.match.params.sessionId, props.match.params.scanId).then((res) => {
      setUpper(res.urls.upper);
      setLower(res.urls.lower);
    })*/
    setauditLoading(true);
    fetchAudits();
  }, [])

  async function formSubmit(target) {
    target.preventDefault();
    let text = "";
    let formData = {};
    if (comment !== '') {
      formData.comment = comment;
      text = "Comment has been added."
    }
    if (data.status !== status) {
      formData.status = status;
    }
    if (scanCreds.assignedToId && data.assignedTo.id !== scanCreds.assignedToId) {
      formData.assignedToId = scanCreds.assignedToId;
    }
    if (Object.keys(formData).length !== 0) {
      await scanService.updateScan(
        props.match.params.scanId,
        formData).then(
          (res) => {
            if (res.success === true) {
                /*toast('🦄 Wow so easy!', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });*/
              setDisableButton(true);
              if (Object.keys(formData).length > 1) {

                setMsg({ ...msg, success: "Changes have been updated successfully!", invalid: "" });
              }
              else {
                setMsg({ ...msg, success: text ? text : "Scan has been updated successfully!", invalid: "" });
              }
              setauditLoading(true);
              setTimeout(() => fetchAudits(), 1000);
              if (formData.hasOwnProperty('status') || formData.hasOwnProperty('assignedToId')) {
                setLoading(true);
                setTimeout(() => fetchScan(), 1000);
              }
              setMsg({ ...msg, success: text, invalid: "" });
              if (formData.hasOwnProperty('comment')) {
                setComment('');
              }

            }
          })
    }
  }
  // function formSubmit() {
  //   alert('Update clicked');
  // }

  /*function onSelectAssignedTo(eventKey) {
    setAssignedTo(vendorUsers[eventKey].name);
  }

  function onSelectStatus(eventKey) {
    setStatus(sessionStatuses[eventKey]);
  }*/

  return (
    <div className="main-content">
      <Grid fluid className="ds-scan-details">
        <Card
          title="Overview"
          content={
            <Row className="ds-patient-info">
              <Col md={4} className="patient-info__userinfo">
                <Row>
                  <Col md={4}>
                    <div className="userinfo__icon">
                      <i className="fa fa-user" />
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className="scan-details__heading">
                      <h5>
                        <strong>{data.profile && data.profile.name}</strong>
                      </h5>
                    </div>
                    <div className="scan-details__content">
                      <span>{data.profile && data.profile.user.email}</span>
                    </div>
                    <div className="scan-details__content">
                      <span>{data.profile && data.profile.phoneNumber}</span>
                    </div>
                    <div className="ds-chips">
                      <div className="chips__content warning">{data.scan && data.scan.status}</div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={4} className="patient-info__scan-detail">
                <div className="scan-details__heading">
                  <h5>Scan Details</h5>
                </div>
                <div className="scan-details__content">
                  Last Scan: {data.scan && new Date(data.scan.createdAt).toISOString().slice(0, 10)}
                </div>
              </Col>
              <Col md={4} className="patient-info__vendor-detail">
                <div className="scan-details__heading">
                  <h5>Vendor Details</h5>
                </div>
                <div className="scan-details__content">{data.assignedTo && data.assignedTo.name}</div>
              </Col>
            </Row>
          }
        />
        <Row>
          <Col md={6}>
            <Card
              title="Maxillary"
              content={
                <STLViewer
                  onSceneRendered={(element) => {
                    console.log(element);
                  }}
                  sceneClassName="test-scene"
                  url="https://dentskan-demo.s3.amazonaws.com/BW_Maxillary.stl"
                  className="obj"
                  modelColor="#ccc6c6"
                />
              }
            />
          </Col>
          <Col md={6}>
            <Card
              title="Mandibular"
              content={
                <STLViewer
                  onSceneRendered={(element) => {
                    console.log(element);
                  }}
                  sceneClassName="test-scene"
                  url="https://dentskan-demo.s3.amazonaws.com/BW_Mandibular.stl"
                  className="obj"
                  modelColor="#ccc6c6"
                />
              }
            />
          </Col>
        </Row>


        <Row>
          <Col md={12}>
            <Card
              title="Scan History"
              content={
                <div className="scan-details__history">
                  <div className="scan-details__container">
                    {auditData.map((user) => {
                      return (
                        <div className="scan-details__content clearfix">
                          <div className="scan-history__profilepic">
                            <i className="fa fa-user"></i>
                          </div>
                          <div className="scan-history__content">
                            <div className="scan-history__heading">
                              {Object.keys(user)[0]}
                            </div>
                            <div className="scan-history__tasks">
                              <ul className="scan-history__tasks--li">
                                {Object.values(user)[0].map((x, index) => {
                                  if (x.field === 'status') {
                                    return (
                                      <li key={index} className="tasks__li set-status">
                                        <div>
                                          Changed Scan {" "}
                                          <span className="task__status">
                                            {x.field}
                                          </span>
                                      from
                                      <span className="task__status">
                                            {x.previousValue}
                                          </span>
                                      To
                                      <span className="task__status">
                                            {x.currentValue}
                                          </span>
                                          <small className="status__date-time">
                                            {moment(x.createdAt).fromNow()}
                                          </small>
                                        </div>
                                      </li>
                                    )
                                  }
                                  else if (x.field === "assignedToId") {
                                    return (
                                      <li key={index} className="tasks__li task-assigned">
                                        <div>
                                          <strong className="task__status task__assigned">
                                            Assigned to
                                          </strong>
                                          Set to
                                          <strong className="task__status assigned-username">
                                            {x.currentValue}
                                          </strong>
                                          <small className="status__date-time">
                                            {moment(x.createdAt).fromNow()}
                                          </small>
                                        </div>
                                      </li>
                                    )
                                  }
                                  else if (x.comment !== undefined) {
                                    return (
                                      <li key={index} className="tasks__li task__comments">
                                        <div>
                                          <p className="task__users-comment">
                                            {x.comment}
                                            <Button className="dlt-commnet-btn" onClick={(e) => handleCommentDelete(e, x.id)} bsSize="xsmall"><Glyphicon glyph="trash" /></Button>
                                          </p>
                                          <small className="status__date-time">
                                            {moment(x.createdAt).fromNow()}
                                          </small>
                                        </div>
                                      </li>
                                    )
                                  }
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                    <div className="scan-details__comment">
                      <Form>
                        <FormGroup controlId="formControlsTextarea">
                          <FormControl
                            componentClass="textarea"
                            placeholder="Comment"
                            value={comment}
                            onChange={(event) => {
                              if (event.value !== '') { setDisableButton(false); }
                              setComment(event.target.value)
                            }} />
                        </FormGroup>
                        <FormGroup className="action-btns clearfix">
                          <Button bsStyle="primary" wd fill disabled={disableButton} onClick={(e) => formSubmit(e)}>
                            {auditloading && <ReactLoading className="btn-loading" type="spin" color="#ffffff" height={20} width={20} />}
                            Update
                          </Button>
                           <div className="pull-right">
                            <div className="pull-right">
                           {/*   <div className="comment__actions">
                                <small>Assigned to:</small>
                                <Select
                                  menuPlacement="top"
                                  maxMenuHeight={250}
                                  isSearchable="true"
                                  placeholder="Set assignee"
                                  value={vendorUsers.filter(option => option.value === assignedTo.id)}
                                  name="assignedTo"
                                  className="actions_dropdown"
                                  options={vendorUsers}
                                  onChange={(event) => {
                                    if (event.value !== assignedTo.id) { setDisableButton(false); }
                                    setscanCreds({ ...scanCreds, assignedToId: event.value });
                                    setAssignedTo({ name: event.name, id: event.value })
                                  }}
                                />
                              </div>*/}
                              <div className="comment__actions">
                                <small>Status: </small>
                                <Select
                                  menuPlacement="top"
                                  maxMenuHeight={250}
                                  isSearchable="true"
                                  placeholder="Set status "
                                  name="status"
                                  value={scanStatuses.filter(option => option.value === status)}
                                  className="actions_dropdown"
                                  options={scanStatuses}
                                  onChange={(event) => {
                                    if (event.value !== status) { setDisableButton(false); }
                                    setStatus(event.value);
                                    setscanCreds({ ...scanCreds, status: event.value })
                                  }}
                                />
                              </div>
                            </div> 
                          </div>
                        </FormGroup>
                      </Form>
                      <div>
                        {msg.success && (
                          <div
                            className="alert alert-success mt-2"
                            style={{
                              display: msg.success ? 'block' : 'none',
                            }}
                            role="alert"
                          >
                            {' '}
                            {msg.success}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}
