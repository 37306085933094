import React, { useState, useEffect } from "react";
import debounce from 'components/Debounce';
// react component for creating dynamic tables
import Table from "components/Table/Table.jsx";
import Select from 'react-select';
import moment from 'moment';
import ReactLoading from 'react-loading';
import SelectColumnFilter from "components/Table/SelectColumnFilter.jsx";

import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import Modal from "react-bootstrap/lib/Modal";
import Form from "react-bootstrap/lib/Form";
import FormGroup from "react-bootstrap/lib/FormGroup";
import ControlLabel from "react-bootstrap/lib/ControlLabel";
import FormControl from "react-bootstrap/lib/FormControl";
import Glyphicon from "react-bootstrap/lib/Glyphicon";

import Datetime from 'react-datetime';
import { validate } from 'indicative/validator';
import orderService from 'services/orderService';
import commentService from 'services/commentService';
import 'react-datetime/css/react-datetime.css';
import { useHistory } from 'react-router';
import Card from "components/Card/Card.jsx";
import sessionService from 'services/sessionService';
import userService from 'services/userService';
import Button from "components/CustomButton/CustomButton.jsx";
import { sessionStatuses, scanStatuses } from 'variables/Variables.jsx';
import userProfilePic from "assets/brand/Icon.png";
import Can from "../components/Can";

function Session(props) {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [auditloading, setauditLoading] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const [showOrderCreate, setShowOrderCreate] = useState(false);
  const [msg, setMsg] = React.useState({ "success": "", "invalid": "" });
  const [ordermsg, setOrderMsg] = React.useState({ "success": "", "invalid": "" });
  const [validatorMessage, setValidatorMessage] = React.useState(null);

  const handleClose = () => {
    setShow(false); setValidatorMessage(null);
    setMsg({ ...msg, success: "", invalid: "" })
  };
  const handleShow = () => {
    setShow(true); setValidatorMessage(null);
    setMsg({ ...msg, success: "", invalid: "" })
  };
  const [tableData, setTableData] = React.useState([]);
  const [name, setName] = React.useState();

  const [vendorUsers, setVendorUsers] = React.useState([]);
  const [assignedTo, setAssignedTo] = React.useState({ name: '', id: '' });
  const [status, setStatus] = React.useState();
  const [disableButton, setDisableButton] = useState(true);
  const [comment, setComment] = React.useState('');
  const [data, setData] = React.useState({});
  const [dates, setDates] = React.useState({ startDate: '', endDate: '' });
  const [auditData, setAuditData] = React.useState([]);
  const [orderData, setOrderData] = React.useState([]);
  const [orderCreds, setorderCreds] = React.useState({
    id: "",
    sessionId: props.match.params.sessionId,
    amount: "",
    description: "",
    assignedToId: "",
  });
  const [selectData, setSelectData] = React.useState({
    statuses: scanStatuses,
    vendorUsers,
  });
  const handleChange = (e) => {
    setValidatorMessage(null);
    setMsg({ ...msg, success: "", invalid: "" });
    setOrderMsg({ ...msg, success: "", invalid: "" });
    if (e.target.name === 'amount') {
      const re = /^[0-9\b]+$/;
      if (re.test(e.target.value)) {
        setorderCreds({
          ...orderCreds,
          ['amount']: e.target.value,
        });
      }
    }
    else {
      setorderCreds({
        ...orderCreds,
        [e.target.name]: e.target.value,
      });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const rules = {
      amount: 'required',
      description: 'string',
    };
    const messages = {
      required: (field) => `${(field.charAt(0).toUpperCase() + field.slice(1)).replace(/([a-z])([A-Z-_])/g, '$1 $2')} is required`,
    };
    try {
      const resp = await validate(orderCreds, rules, messages);

      let payload = {
        sessionId: orderCreds.sessionId,
        amount: orderCreds.amount,
        description: orderCreds.description,
        assignedToId: orderCreds.assignedToId,
      }
      orderService.addOrder(payload).then((res) => {
        if (res.success === true) {
          setValidatorMessage(null);
          setOrderMsg({ ...ordermsg, success: "Order has been added successfully!", invalid: "" });
          setTimeout(() => setShowOrderCreate(false), 500);
          setTimeout(() => fetchScans({}), 1500);
        }
        else {
          setValidatorMessage(null);
          setOrderMsg({ ...ordermsg, success: "", invalid: res.msg });
        }
      }
      );
    } catch (e) {
      setOrderMsg({ ...ordermsg, invalid: "", success: "" });
      setValidatorMessage(e[0].message);
    }

  };
  function fetchAudits() {
    sessionService.getAudit(props.match.params.sessionId).then((res) => {
      let audit = [];
      const isKeyExist = (audit, key) => audit.find(x => Object.keys(x)[0] === key) ? true : false;
      const appendData = (audit, key, prop) => (audit.find(x => Object.keys(x)[0] === key) || {})[key].push(prop);
      let name = '';
      res.audit.map((prop) => {
        if (prop.updatedByName) {
          name = prop.updatedByName;
        }
        else {
          name = prop.user.name;
        }
        if (isKeyExist(audit, name)) {
          appendData(audit, name, prop);
        }
        else {
          let updates = [];
          updates.push(prop);
          let item = { [name]: updates };
          audit.push(item);
        }
        return null;
      })
      setAuditData(audit);
      setMsg({ ...msg, success: "", invalid: res.msg });
      setauditLoading(false);
    })
  }
  useEffect(() => {
    setLoading(true);
    userService.getUsers().then((res) => {
      setLoading(false);
      if (res.success) {
        let users = res.users.filter(option => option.role === "Vendor Admin" || option.role === "Vendor Associate");
        users = users.map((user) => { return { value: user.id, label: user.name } });
        setVendorUsers(users);
        setSelectData({
          ...selectData,
          vendors: users,
        });
      }
    })
    setauditLoading(true);
    fetchAudits();
  }, []);

  function fetchScans({ pageSize, pageIndex, sortBy, filters }) {
    setLoading(true);
    sessionService.getSession(props.match.params.sessionId, pageSize, pageIndex, sortBy, filters).then((res) => {
      setLoading(false); setMsg({ ...msg, success: "", invalid: res.msg });
      if (res.success) {
        let dataTable = res.session;
        setName(dataTable.profile.name);
        setTableData(
          dataTable.scans.map((prop) => {
            return {
              id: prop.id,
              scanDate: new Date(prop.createdAt).toISOString().slice(0, 10),
              status: prop.status,
              actions: (
                // we've added some custom button actions
                <div className="actions-right">
                  <Button
                    onClick={(e) => {
                      let obj = dataTable.scans.find((o) => o.id === prop.id);
                      history.push(
                        `/admin/session/${props.match.params.sessionId}/scan/${obj.id}`
                      );
                    }}
                    bsStyle="primary"
                    simple
                    icon
                  >
                    <i className="fa fa-eye" />
                  </Button>{" "}
                </div>
              ),
            };
          }),
        );
        setTotalCount(res.count);
        setPageCount(Math.ceil(res.count / pageSize));
        let i = res.session.scans.length;
        if (res.session.assignedTo) {
          setAssignedTo({
            name: res.session.assignedTo.name,
            id: res.session.assignedTo.id
          });
        }
        setStatus(res.session.status);
        setData(res.session);
        orderService.getUserOrderStats(res.session.profile.user.id).then((res) => {
          console.log(res.response);
          setOrderData(res.response);
        });
        setDates({
          startDate: (new Date(res.session.startDate) || new Date()),
          endDate: (new Date(res.session.endDate) || new Date(Date.now() + 1000 /*sec*/ * 60 /*min*/ * 60 /*hour*/ * 24 /*day*/ * 10))
        });

      }
    });
  }
  const fetchData = React.useCallback(debounce(fetchScans, 500), []);
  async function formSubmitModal(target) {
    target.preventDefault();
    let formData = {};
    if (data.startDate !== dates.startDate)
      formData.startDate = dates.startDate;
    if (data.endDate !== dates.endDate)
      formData.endDate = dates.endDate;
    if (Object.keys(formData).length !== 0) {
      await sessionService.updateSession(
        props.match.params.sessionId,
        formData).then(
          (res) => {
            setTimeout(() => setShow(false), 3000);
            setDisableButton(true);
            setValidatorMessage(null);
            setMsg({ ...msg, success: "Session has been updated successfully!", invalid: "" });
            setTimeout(() => fetchScans({}), 500);
          })
    }
  }
  const handleCommentDelete = (e, id) => {
    e.preventDefault();
    commentService.deleteComment(id).then((res) => {
      if (res.success === true) {
        setMsg({ ...msg, success: "Comment has been deleted", invalid: "" });
        setauditLoading(true);
        setTimeout(() => fetchAudits(), 300);
      }
      else {
        setMsg({ ...msg, success: "", invalid: "Comment was not deleted" });
      }
    })
  }
  async function formSubmit(target) {
    target.preventDefault();
    let formData = {};
    let text = '';
    if (comment !== '') {
      formData.comment = comment;
      text = 'Comment has been added.'
    }
    if (data.assignedToId !== assignedTo.id) {
      formData.assignedToId = assignedTo.id;
    }
    if (data.status !== status)
      formData.status = status;
    if (Object.keys(formData).length !== 0) {
      await sessionService.updateSession(
        props.match.params.sessionId,
        formData).then(
          (res) => {
            if (res.success === true) {
              setDisableButton(true);
              if (Object.keys(formData).length > 1) {
                setMsg({ ...msg, success: "Changes have been updated successfully!", invalid: "" });
              }
              else {
                setMsg({ ...msg, success: text ? text : "Session has been updated successfully!", invalid: "" });
              }
              setauditLoading(true);
              setTimeout(() => fetchAudits(), 1000);
              if (formData.hasOwnProperty('status') || formData.hasOwnProperty('assignedToId')) {
                setTimeout(() => fetchScans({}), 500);
              }
              if (formData.hasOwnProperty('comment')) {
                setComment('');
              }
            }
            else {
              setMsg({ ...msg, success: "", invalid: res.msg });
            }
          })
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "Scan Date",
        accessor: "scanDate",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: "Status",
        accessor: "status",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableSortBy: true,
        disableFilters: true,
      },
    ])
  return (
    <div className="main-content">
      <Grid fluid className="ds-scan-details">
        <Row>
          <Col md={12}>
            <Card
              title="Overview"
              content={
                <>
                  <Row className="ds-patient-info">
                    <Col md={4} className="patient-info__userinfo">
                      <Row>
                        <Col md={4}>
                          <div className="userinfo__icon">
                            <i className="fa fa-user" />
                          </div>
                        </Col>
                        <Col md={8}>
                          <div className="scan-details__heading">
                            <h5>
                              <strong>{data.profile && data.profile.name}</strong>
                            </h5>
                          </div>
                          <div className="scan-details__content">
                            <span>{data.profile && data.profile.user.email}</span>
                          </div>
                          <div className="scan-details__content">
                            <span>{data.profile && data.profile.phoneNumber}</span>
                          </div>
                          <div className="ds-chips">
                            <div className="chips__content warning">{data.status ? data.status : "-"}</div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={4} className="patient-info__scan-detail">
                      <div className="scan-details__heading">
                        <h5>Session Details</h5>
                      </div>
                      <div className="scan-details__content">
                        {// TBD: this should point to last scan, currently it takes the first.
                        }
                        {/* Last Scan: {data.scans && new Date(data.scans[0].createdAt).toISOString().slice(0, 10)} */}
                      </div>
                      <div className="scan-details__content">
                        Start Date: {data.startDate && new Date(data.startDate).toISOString().slice(0, 10)}
                      </div>
                      <div className="scan-details__content">
                        End Date: {data.startDate && new Date(data.endDate).toISOString().slice(0, 10)}
                      </div>
                    </Col>
                    <Col md={4} className="patient-info__vendor-detail">
                      <div className="scan-details__heading">
                        <h5>Assignee</h5>
                      </div>
                      <div className="scan-details__content">{data.assignedTo && data.assignedTo.name}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pull-right" md={4}>
                      <Button className="pull-right" bsStyle="primary" wd fill
                        onClick={handleShow}>Edit</Button>
                    </Col>
                  </Row>
                </>
              }
            />
          </Col>
        </Row>
        <Row className="ds-table-row">
          <Col md={12}>
            <Card
              title={`Scans for ${name}`}
              content={
                <Table
                  columns={columns}
                  data={tableData}
                  fetchData={fetchData}
                  loading={loading}
                  pageCount={pageCount}
                  totalCount={totalCount}
                  selectData={selectData}
                />
              }
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card
              title="Session History"
              content={
                <div className="scan-details__history">
                  <div className="scan-details__container">
                    {auditData.map((user) => {
                      return (
                        <div className="scan-details__content clearfix">
                          <div className="scan-history__profilepic">
                            <i class="fa fa-user"></i>
                          </div>
                          <div className="scan-history__content">
                            <div className="scan-history__heading">
                              {Object.keys(user)[0]}
                            </div>
                            <div className="scan-history__tasks">
                              <ul className="scan-history__tasks--li">
                                {Object.values(user)[0].map((x, index) => {
                                  if (x.field === 'status') {
                                    return (
                                      <li key={index} className="tasks__li set-status">
                                        <div>
                                          Changed Scan {" "}
                                          <span className="task__status">
                                            {x.field}
                                          </span>
                                       from
                                      <span className="task__status">
                                            {x.previousValue}
                                          </span>
                                      To
                                      <span className="task__status">
                                            {x.currentValue}
                                          </span>
                                          <small className="status__date-time">
                                            {moment(x.createdAt).fromNow()}
                                          </small>
                                        </div>
                                      </li>
                                    )
                                  } else if (x.field === "assignedTo") {
                                    return (
                                      <li key={index} className="tasks__li task-assigned">
                                        <div>
                                          <strong className="task__status task__assigned">
                                            Assigned to
                                          </strong>
                                          Set to
                                          <strong className="task__status assigned-username">
                                            {x.currentValue}
                                          </strong>
                                          <small className="status__date-time">
                                            {moment(x.createdAt).fromNow()}
                                          </small>
                                        </div>
                                      </li>
                                    )
                                  } else if (x.comment !== undefined) {
                                    return (
                                      <li key={index} className="tasks__li task__comments">
                                        <div>
                                          <p className="task__users-comment">
                                            {x.comment}
                                            <Button className="dlt-commnet-btn" onClick={(e) => handleCommentDelete(e, x.id)} bsSize="xsmall">
                                              <Glyphicon glyph="trash" />
                                            </Button>
                                          </p>
                                          <small className="status__date-time">
                                            {moment(x.createdAt).fromNow()}
                                          </small>
                                        </div>
                                      </li>
                                    )
                                  }
                                  else if (x.field === 'startDate' || x.field === 'endDate') {
                                    return (
                                      <li key={index} className="tasks__li set-status">
                                        <div>
                                          Changed Session {" "}
                                          <span className="task__status">
                                            {x.field}
                                          </span>
                                       from
                                      <span className="task__status">
                                            {x.previousValue && new Date(x.previousValue).toISOString().slice(0, 10)}
                                          </span>
                                      To
                                      <span className="task__status">
                                            {x.currentValue && new Date(x.currentValue).toISOString().slice(0, 10)}
                                          </span>
                                          <small className="status__date-time">
                                              {moment(x.createdAt).fromNow()}
                                          </small>
                                        </div>
                                      </li>
                                    )
                                  }
                                  return null;
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                    <div className="scan-details__comment">
                      <Form>
                        <FormGroup controlId="formControlsTextarea">
                          <FormControl
                            componentClass="textarea"
                            placeholder="Comment"
                            value={comment}
                            onChange={(event) => {
                              if (event.value !== '') { setDisableButton(false); }
                              setComment(event.target.value)
                            }} />
                        </FormGroup>
                        <FormGroup className="action-btns clearfix">
                          <Button bsStyle="primary" disabled={disableButton} wd fill onClick={formSubmit}>
                            {auditloading && <ReactLoading className="btn-loading" type="spin" color="#ffffff" height={20} width={20} />}
                            Update</Button>
                          <div className="pull-right">
                            <div className="comment__actions">
                              <small>Assigned to:</small>
                              <Select
                                menuPlacement="top"
                                maxMenuHeight={250}
                                isSearchable="true"
                                placeholder="Set assignee"
                                value={vendorUsers.filter(option => option.value === assignedTo.id)}
                                name="assignedTo"
                                className="actions_dropdown"
                                options={vendorUsers}
                                onChange={(assignee) => {
                                  if (assignee.value !== assignedTo.id) { setDisableButton(false); }
                                  setAssignedTo({ name: assignee.name, id: assignee.value })
                                }}
                              />
                            </div>
                            <div className="comment__actions">
                              <small>Status: </small>
                              <Select
                                menuPlacement="top"
                                maxMenuHeight={250}
                                isSearchable="true"
                                placeholder="Set status "
                                name="status"
                                value={sessionStatuses.filter(option => option.value === status)}
                                className="actions_dropdown"
                                options={sessionStatuses}
                                onChange={(status) => {
                                  if (status.value !== status) { setDisableButton(false); }
                                  setStatus(status.value)
                                }}
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </Form>
                      <div>
                        {msg.success && (
                          <div
                            className="alert alert-success mt-2"
                            style={{
                              display: msg.success ? 'block' : 'none',
                            }}
                            role="alert"
                          >
                            {' '}
                            {msg.success}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              }
            />
          </Col>
        </Row>
        {Can("orders-page:create") && <Row>
          <Col md={12}>
            <Card
              title={`Orders of ${name}`}
              content={
                <>
                  <Row className="ds-patient-info">

                    <Col md={4} className="patient-info__order-detail">
                      <div className="scan-details__heading">
                        <h5>Order Details</h5>
                      </div>
                      <div className="order-details__content">
                        {// TBD: this should point to last scan, currently it takes the first.
                        }
                        {/* Last Scan: {data.scans && new Date(data.scans[0].createdAt).toISOString().slice(0, 10)} */}
                      </div>
                      <div className="scan-details__content">
                        Latest Order: {orderData.latest_order ? new Date(orderData.latest_order).toISOString().slice(0, 10) : "No Orders"}
                      </div>
                      <div className="scan-details__content">
                        Last Fulfilled Order: {orderData.last_fulfilled_order ? new Date(orderData.last_fulfilled_order).toISOString().slice(0, 10) : "No Orders"}
                      </div>
                    </Col>
                    <Col md={4} className="patient-info__vendor-detail">
                      <div className="order-details__heading">
                        <h5>Assignee</h5>
                      </div>
                      <div className="scan-details__content">{data.assignedTo && data.assignedTo.name}</div>
                    </Col>
                    <Col md={4} className="patient-info__vendor-detail">
                      <div className="scan-details__content">
                        Total Orders of {name}: {orderData.orders ? orderData.orders : 0}
                      </div>
                      <Button bsStyle="info" wd fill
                        onClick={() => { setShowOrderCreate(true); setOrderMsg({ ...ordermsg, success: "", invalid: "" }); setValidatorMessage(null); }}>Add Order</Button>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col md={4}>
                      
                    </Col>
                  </Row> */}
                </>
              }
            />
          </Col>
        </Row>}
      </Grid>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Session</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Form>
                <div>
                  {validatorMessage && (
                    <div
                      className="alert alert-danger mt-2"
                      style={{
                        display: validatorMessage ? 'block' : 'none',
                      }}
                      role="alert"
                    >
                      {' '}
                      {validatorMessage}
                    </div>
                  )}
                </div>
                <div>
                  {msg.invalid && (
                    <div
                      className="alert alert-danger mt-2"
                      style={{
                        display: msg.invalid ? 'block' : 'none',
                      }}
                      role="alert"
                    >
                      {' '}
                      {msg.invalid}
                    </div>
                  )}
                </div>
                <div>
                  {msg.success && (
                    <div
                      className="alert alert-success mt-2"
                      style={{
                        display: msg.success ? 'block' : 'none',
                      }}
                      role="alert"
                    >
                      {' '}
                      {msg.success}
                    </div>
                  )}
                </div>
                <FormGroup >
                  <ControlLabel>Start date</ControlLabel>
                  <Datetime
                    inputProps={{ placeholder: "Session start date" }}
                    value={dates.startDate}
                    timeFormat={false}
                    onChange={(date) => { setDisableButton(false); setDates({ ...dates, startDate: new Date(date._d) }) }}
                  />
                </FormGroup>
                <FormGroup >
                  <ControlLabel>End date</ControlLabel>
                  <Datetime
                    inputProps={{ placeholder: "Session end date" }}
                    value={dates.endDate}
                    timeFormat={false}
                    onChange={(date) => { setDisableButton(false); setDates({ ...dates, endDate: new Date(date._d) }) }}
                  />
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" disabled={disableButton} onClick={(e) => { formSubmitModal(e) }}>
            Update
            </Button>
          <Button bsStyle="default" onClick={handleClose}>
            Close
            </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showOrderCreate} onHide={() => setShowOrderCreate(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form horizontal>
            <div>
              {validatorMessage && (
                <div
                  className="alert alert-danger mt-2"
                  style={{
                    display: validatorMessage ? 'block' : 'none',
                  }}
                  role="alert"
                >
                  {' '}
                  {validatorMessage}
                </div>
              )}
            </div>
            <div>
              {ordermsg.invalid && (
                <div
                  className="alert alert-danger mt-2"
                  style={{
                    display: ordermsg.invalid ? 'block' : 'none',
                  }}
                  role="alert"
                >
                  {' '}
                  {ordermsg.invalid}
                </div>
              )}
            </div>
            <div>
              {ordermsg.success && (
                <div
                  className="alert alert-success mt-2"
                  style={{
                    display: ordermsg.success ? 'block' : 'none',
                  }}
                  role="alert"
                >
                  {' '}
                  {ordermsg.success}
                </div>
              )}
            </div>
            <FormGroup controlId="formHorizontalAmount">
              <Col componentClass={ControlLabel} sm={3}>
                Amount
              </Col><Col sm={9}>
                <FormControl type="text" name="amount" onChange={handleChange} placeholder="Amount" />
              </Col>
            </FormGroup>
            <FormGroup controlId="formHorizontalAssignedTo">
              <Col componentClass={ControlLabel} sm={3}>
                Assigned To:
              </Col>
              <Col sm={9}>
                <Select
                  menuPlacement="top"
                  maxMenuHeight={250}
                  isSearchable="true"
                  placeholder="Assigned To"
                  name="assignedToId"
                  options={vendorUsers}
                  onChange={(vendorId) => {
                    setorderCreds({
                      ...orderCreds,
                      assignedToId: vendorId.value
                    });
                  }}

                />
              </Col>
            </FormGroup>
            <FormGroup controlId="formControlsTextarea">
              <Col componentClass={ControlLabel} sm={3}>
                Description:
              </Col>
              <Col sm={9}>
                <FormControl
                  componentClass="textarea"
                  placeholder="Add order description"
                  name="description"
                  onChange={handleChange}
                /></Col>
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" bsStyle="info" fill onClick={handleSubmit}>Add Order</Button>
          <Button bsStyle="default" onClick={() => { setShowOrderCreate(false); setOrderMsg({ success: "", invalid: "" }); setValidatorMessage(null); }}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Session;
