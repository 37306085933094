import React, { useContext, useState, useEffect } from "react";
// react component for creating dynamic tables
import Table from "components/Table/Table.jsx";
import { useHistory } from 'react-router';
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import userService from 'services/userService';
import vendorService from 'services/vendorService';
import { validate } from 'indicative/validator';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import debounce from 'components/Debounce';
import { deviceStatuses, roles } from '../variables/Variables.jsx';
import ColumnFilter from "components/Table/ColumnFilter.jsx";
import SelectColumnFilter from "components/Table/SelectColumnFilter.jsx";
import Can from "../components/Can.jsx";
import { authContext } from 'state/AuthProvider';
import Select from 'react-select';

import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import Modal from "react-bootstrap/lib/Modal";
import Form from "react-bootstrap/lib/Form";
import FormGroup from "react-bootstrap/lib/FormGroup";
import ControlLabel from "react-bootstrap/lib/ControlLabel";
import FormControl from "react-bootstrap/lib/FormControl";

function Users(props) {
  const history = useHistory();
  const { auth } = useContext(authContext);
  const [loading, setLoading] = React.useState(true);
  const [pageCount, setPageCount] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const [show, setShow] = useState(false);
  const [item, setItem] = useState({});
  const [msg, setMsg] = React.useState({ "success": "", "invalid": "" });
  const [validatorMessage, setValidatorMessage] = React.useState(null);
  const [showUserCreate, setShowUserCreate] = useState(false);
  const [data, setData] = React.useState([]);
  const [vendors, setVendors] = React.useState([]);
  const [creds, setCreds] = React.useState({
    name: "",
    email: "",
    password: "",
    phoneNumber: "",
    role: "Vendor Admin",
    dob: "",
    vendorId: null,
  }

  );

  const handleClose = () => setShow(false);
  function getFormattedAddress(obj) {
    if (obj === null || obj === undefined)
      return;
    let completeAddr = 'No address on record';
    if (obj.address) {
      let components = [obj.address.address1, obj.address.address2, obj.address.city, obj.address.zipcode, obj.address.country]
      completeAddr = components.filter(Boolean).join(', ');
    }
    return completeAddr;
  }

  function fetchUsers({ pageSize, pageIndex, sortBy, filters }) {
    setLoading(true);
    userService.getUsers(pageSize, pageIndex, sortBy, filters).then((res) => {
      setLoading(false);
      if (res.success) {
        let dataTable = res.users;
        setTotalCount(res.count);
        setPageCount(Math.ceil(res.count / pageSize));
        setData(
          dataTable.map((prop) => {
            return {
              id: prop.id,
              name: prop.name,
              email: prop.email,
              phoneNumber: prop.phoneNumber,
              role: prop.role,
              address: prop.address,
              dob: prop.dob,
              actions: (
                <div className="actions-right">
                  <Button
                    bsStyle="primary"
                    simple
                    icon
                    onClick={(e) => {
                      let obj = dataTable.find((o) => o.id === prop.id);
                      setItem(obj);
                      setShow(true);
                    }}
                  >
                    <i className="fa fa-eye" />
                  </Button>{" "}
                  {Can("users-page:edit") && dataTable.find((o) => o.id === prop.id).role != 'User' &&
                    <Button
                      onClick={(e) => {
                        let obj = dataTable.find((o) => o.id === prop.id);
                        history.push(`/admin/user/${obj.id}/profile`);
                      }}
                      bsStyle="warning"
                      simple
                      icon
                    >
                      <i className="fa fa-edit" />
                    </Button>}
                </div>
              ),
            };
          }),
        );
      }
    });
  }
  const fetchData = React.useCallback(debounce(fetchUsers, 500), []);

  useEffect(() => {
    if (Can("users-page:create") && Can("users-page:admin")) {
      vendorService.vendors().then((res) => {
        if (res.success) {
          const v = res.vendors.map((vendor) => {
            return { label: vendor.name, value: vendor.id };
          })
          setVendors(v);
        }
      })
    }
  }, []);

  const handleChange = (e) => {
    setValidatorMessage(null);
    setCreds({
      ...creds,
      [e.target.name]: e.target.value,
    });
  };
  const handlePhoneChange = e => {
    setCreds({ ...creds, phoneNumber: e });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const rules = {
      email: 'required|email',
      password: 'required|min:4|max:40',
      name: 'required',
      role: 'required',
      phoneNumber: 'required',
      dob: 'required',
    };
    const messages = {
      required: (field) => `${field.charAt(0).toUpperCase() + field.slice(1).replace(/([a-z])([A-Z-_])/g, '$1 $2')} is required`,
      'password.min': 'Password is too short',
      'email.email': 'Please enter a valid email address',
      'password.max': 'Password is too long',
    };
    const error = [{ message: "Please enter a valid phone number" }];
    try {
      const resp = await validate(creds, rules, messages);
      const isValidNumber = isPossiblePhoneNumber(creds.phoneNumber);
      if (!isValidNumber) {
        throw error;
      }
      let params = {
        name: creds.name,
        email: creds.email,
        password: creds.password,
        role: creds.role,
        phoneNumber: creds.phoneNumber,
        dob: creds.dob,
        vendorId: creds.vendorId ? creds.vendorId : null,
      }
      userService.addUser(params).then((res) => {
        if (res.success === true) {
          setValidatorMessage(null);
          setMsg({ ...msg, success: "Profile Created Successfully!", invalid: "" });
          setTimeout(() => setShowUserCreate(false), 3000);
        }
        else {
          setValidatorMessage(null);
          setMsg({ ...msg, success: "", invalid: res.msg });
        }
      }
      );
    } catch (e) {
      console.log(e);
      setMsg({ ...msg, invalid: "", success: "" });
      setValidatorMessage(e[0].message);
    }

  };

  const selectData = {
    deviceStatuses,
    roles,
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Filter: ColumnFilter,
      },
      {
        Header: "Email",
        accessor: "email",
        Filter: ColumnFilter,
      },
      {
        Header: "Role",
        accessor: "role",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Phone Number",
        accessor: "phoneNumber",
        Filter: ColumnFilter,
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableSortBy: true,
        disableFilters: true,
      },
    ])

  return (
    <div className="main-content">
      <Grid fluid>
        <Row className="ds-table-row">
          <Col md={12}>
            <Card
              title="Users"
              content={
                <Table
                  columns={columns}
                  data={data}
                  fetchData={fetchData}
                  loading={loading}
                  pageCount={pageCount}
                  totalCount={totalCount}
                  selectData={selectData}
                />
              }
            />
          </Col>
        </Row>
        {Can("users-page:create") && <Row>
          <Col md={12}>
            <Button bsStyle="primary" pullRight wd fill onClick={() => {
              setMsg({ ...msg, success: "", invalid: "" })
              setShowUserCreate(true)
            }}>
              {" "}
              Add Users
            </Button>
          </Col>
        </Row>}
      </Grid>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>User Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={3}>
              <label>Name: </label>
            </Col>
            <Col md={9}>
              <span>{item.name}</span>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <label>Email: </label>
            </Col>
            <Col md={9}>
              <span>{item.email}</span>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <label>Role: </label>
            </Col>
            <Col md={9}>
              <span>{item.role}</span>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <label>Phone Number: </label>
            </Col>
            <Col md={9}>
              <span>{item.phoneNumber}</span>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <label>Address: </label>
            </Col>
            <Col md={9}>
              <span>{getFormattedAddress(item)} </span>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="default" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showUserCreate} onHide={() => setShowUserCreate(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form horizontal>
            <div>
              {validatorMessage && (
                <div
                  className="alert alert-danger mt-2"
                  style={{
                    display: validatorMessage ? 'block' : 'none',
                  }}
                  role="alert"
                >
                  {' '}
                  {validatorMessage}
                </div>
              )}
            </div>
            <div>
              {msg.invalid && (
                <div
                  className="alert alert-danger mt-2"
                  style={{
                    display: msg.invalid ? 'block' : 'none',
                  }}
                  role="alert"
                >
                  {' '}
                  {msg.invalid}
                </div>
              )}
            </div>
            <div>
              {msg.success && (
                <div
                  className="alert alert-success mt-2"
                  style={{
                    display: msg.success ? 'block' : 'none',
                  }}
                  role="alert"
                >
                  {' '}
                  {msg.success}
                </div>
              )}
            </div>
            <FormGroup controlId="formHorizontalEmail">
              <Col componentClass={ControlLabel} sm={2}>
                Email
              </Col>
              <Col sm={10}>
                <FormControl type="email" name="email" onChange={handleChange} placeholder="Email" />
              </Col>
            </FormGroup>

            <FormGroup controlId="formHorizontalPassword">
              <Col componentClass={ControlLabel} sm={2}>
                Password
              </Col>
              <Col sm={10}>
                <FormControl type="password" name="password" onChange={handleChange} placeholder="Password" />
              </Col>
            </FormGroup>
            <FormGroup controlId="formHorizontalName">
              <Col componentClass={ControlLabel} sm={2}>
                Name
              </Col>
              <Col sm={10}>
                <FormControl type="text" name="name" onChange={handleChange} placeholder="Name..." />
              </Col>
            </FormGroup>
            <FormGroup controlId="formControlsSelect">
              <Col componentClass={ControlLabel} sm={2}>
                <ControlLabel>Role</ControlLabel></Col>
              <Col sm={6}>
                <FormControl componentClass="select" name="role" onChange={handleChange} placeholder="Role">
                  <option value="Vendor Admin">Admin</option>
                  <option value="Vendor Associate">Associate</option>
                </FormControl></Col>
            </FormGroup>
            <FormGroup controlId="formHorizontalPhone">
              <Col componentClass={ControlLabel} sm={2}>
                Phone Number
              </Col>
              <Col sm={10}>
                <FormControl componentClass={PhoneInput} defaultCountry="US" name="phoneNumber" onChange={handlePhoneChange} />
              </Col>
            </FormGroup>
            <FormGroup controlId="formHorizontalDob">
              <Col componentClass={ControlLabel} sm={2}>
                Date of Birth
              </Col>
              <Col sm={10}>
                <FormControl type="date" name="dob" onChange={handleChange} placeholder="Date of Birth..." />
              </Col>
            </FormGroup>
            {Can("users-page:create") && Can("users-page:admin") && <FormGroup controlId="formHorizontalCity">
              <Col componentClass={ControlLabel} sm={2}>
                Vendor
              </Col>
              <Col sm={10}>
                <Select
                  menuPlacement="top"
                  maxMenuHeight={250}
                  isSearchable="true"
                  placeholder="Vendor"
                  name="vendorId"
                  options={vendors}
                  onChange={(vendorId) => {
                    setCreds({
                      ...creds,
                      vendorId: vendorId.value
                    });
                  }}

                />
              </Col>
            </FormGroup>}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" bsStyle="primary" fill onClick={handleSubmit}>Create</Button>
          <Button bsStyle="default" onClick={() => { setShowUserCreate(false); setMsg({ success: "", invalid: "" }); setValidatorMessage(null); }}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Users;
