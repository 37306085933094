import React from "react";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import FormGroup from "react-bootstrap/lib/FormGroup";
import ControlLabel from "react-bootstrap/lib/ControlLabel";

import Select from 'react-select';

import Card from "components/Card/Card.jsx";
import FormInputs from "components/FormInputs/FormInputs.jsx";
import { validate } from 'indicative/validator';
import Button from "components/CustomButton/CustomButton.jsx";
import vendorService from 'services/vendorService';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import { useEffect } from "react";
import { useHistory } from "react-router";
import { US_states } from "../../variables/Variables.jsx";
function VendorPage(props) {
  const [creds, setCreds] = React.useState({
    id: "",
    name: "",
    email: "",
    //paypal_email: "",
    //paypal_merchant_id: "",
    phoneNumber: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipcode: "",
  }

  );
  const history = useHistory();
  const [msg, setMsg] = React.useState({ "success": "", "invalid": "", "buttonDisabled": true });
  const [validatorMessage, setValidatorMessage] = React.useState(null);

  useEffect(() => {
    let mounted = true;
    const id = props.match.params.vendorId;
    vendorService.vendor(id).then((res) => {
      if (res.success) {
        setCreds({
          ...creds,
          id: id,
          name: res.vendor.name,
          //paypal_email: res.vendor.paypalEmail,
          phoneNumber: res.vendor.phoneNumber,
          address1: res.vendor.address.address1,
          address2: res.vendor.address.address2 || "",
          //paypal_merchant_id: res.vendor.paypalMerchantID,
          email: res.vendor.email,
          city: res.vendor.address.city,
          state: res.vendor.address.state,
          zipcode: res.vendor.address.zipcode,
          country: res.vendor.address.country,
        });
      } else {
        setMsg({ ...msg, success: "", invalid: "Couldn't load vendor data" });
        setValidatorMessage("");
      }
    });
    return () => (mounted = false);
  }, []);

  const handleChange = e => {
    setValidatorMessage(null);
    setMsg({ success: "", invalid: "" });
    setCreds({
      ...creds,
      [e.target.name]: e.target.value,
    });
  };
  const handlePhoneChange = e => {
    setCreds({ ...creds, phoneNumber: e });
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    const rules = {
      name: 'required',
      address1: 'required',
      city: 'required',
      state: 'required',
      zipcode: 'required',
    };
    const messages = {
      required: (field) => `${field.charAt(0).toUpperCase() + field.slice(1).replace(/([a-z])([A-Z-_])/g, '$1 $2')} is required`,
    };

    try {
      const resp = await validate(creds, rules, messages);
      let params = {
        name: creds.name,
        address1: creds.address1,
        address2: creds.address2,
        city: creds.city,
        state: creds.state,
        zipcode: creds.zipcode,
      };

      let res = await vendorService.updateVendor(props.match.params.vendorId, params);
      if (res.success) {
        setValidatorMessage(null);
        setMsg({ ...msg, success: "Profile Updated Successfully!", invalid: "" });
        setTimeout(() => history.push("/admin/vendors"), 3000);
      }
      else {
        setValidatorMessage(null);
        setMsg({ ...msg, success: "", invalid: "Vendor was not updated!" });
      }
    } catch (e) {
      setMsg({ ...msg, invalid: "", success: "" });
      setValidatorMessage(e[0].message);
    }

  };

  return (
    <div className="main-content" >
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title="Edit Vendor Profile"
              content={
                <form>
                  <div>
                    {validatorMessage && (
                      <div
                        className="alert alert-danger mt-2"
                        style={{
                          display: validatorMessage ? 'block' : 'none',
                        }}
                        role="alert"
                      >
                        {' '}
                        {validatorMessage}
                      </div>
                    )}
                  </div>
                  <div>
                    {msg.invalid && (
                      <div
                        className="alert alert-danger mt-2"
                        style={{
                          display: msg.invalid ? 'block' : 'none',
                        }}
                        role="alert"
                      >
                        {' '}
                        {msg.invalid}
                      </div>
                    )}
                  </div>
                  <div>
                    {msg.success && (
                      <div
                        className="alert alert-success mt-2"
                        style={{
                          display: msg.success ? 'block' : 'none',
                        }}
                        role="alert"
                      >
                        {' '}
                        {msg.success}
                      </div>
                    )}
                  </div>
                  <FormInputs
                    ncols={["col-md-6", "col-md-6"]}
                    proprieties={[
                      {
                        label: "Company Name",
                        name: "company",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Company",
                        defaultValue: creds.name,
                        disabled: true,
                      },
                      {
                        label: "Email",
                        type: "text",
                        name: "email",
                        bsClass: "form-control",
                        placeholder: "Email",
                        defaultValue: creds.email,
                        disabled: true,
                      }
                    ]} />
                  <FormInputs
                    ncols={["col-md-6"]}
                    proprieties={[
                      {
                        label: "Phone Number",
                        componentClass: PhoneInput,
                        name: "phoneNumber",
                        value: creds.phoneNumber,
                        onChange: (e) => handlePhoneChange(e),
                        disabled: false,
                      },
                    ]}
                  />
                  <FormInputs
                    ncols={["col-md-6", "col-md-6"]}
                    proprieties={[
                      {
                        label: "Address 1",
                        type: "text",
                        name: "address1",
                        bsClass: "form-control",
                        value: creds.address1,
                        onChange: (e) => handleChange(e)
                      },
                      {
                        label: "Address 2",
                        type: "text",
                        name: "address2",
                        bsClass: "form-control",
                        value: creds.address2,
                        onChange: (e) => handleChange(e)
                      },
                    ]}
                  />
                  <FormInputs
                    ncols={["col-md-6", "col-md-6"]}
                    proprieties={[
                      {
                        label: "City",
                        type: "text",
                        name: "city",
                        bsClass: "form-control",
                        placeholder: "City",
                        value: creds.city,
                        onChange: (e) => handleChange(e)
                      },
                      {
                        label: "Zipcode",
                        type: "text",
                        name: "zipcode",
                        bsClass: "form-control",
                        placeholder: "Zipcode",
                        value: creds.zipcode,
                        onChange: (e) => handleChange(e)
                      },
                    ]}>
                  </FormInputs>
                  <Row>
                    <FormGroup className="col-lg-6">
                      <ControlLabel>STATE</ControlLabel>
                      <Select
                        placeholder="State"
                        name="state"
                        value={US_states.filter(option => option.value === creds.state)}
                        className="select"
                        options={US_states}
                        onChange={(state) => {
                          setCreds({ ...creds, state: state.value })
                        }}
                      />
                    </FormGroup>
                  </Row>
                  <Button bsStyle="primary" pullRight fill type="submit" onClick={handleSubmit}>
                    Update Profile
                    </Button>
                  <div className="clearfix" />
                </form>
              }
            />
          </Col>
        </Row>
      </Grid>

    </div >
  );
}


export default VendorPage;
