import React from "react";
import FormControl from 'react-bootstrap/lib/FormControl';

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
  state: { selectData },
}) {

  const [selected, setSelected] = React.useState(0);

  const options = React.useMemo(() => {
    const options = [];
    switch (id) {
      case 'vendor':
        let vendors = selectData.vendors || [];
        if (vendors.length > 0) {
          vendors.forEach(row => {
            options.push({ key: row.id, value: row.name })
          })
        }
        break;

      case 'status':
        let statuses = selectData.statuses || [];
        if (statuses.length > 0) {
          statuses.forEach((row) => {
            if (row.value === "Complete" && statuses.length === 6) { }
            else { options.push({ key: row.value, value: row.value }) }
          })
        }
        break;
      case 'role':
        let roles = selectData.roles || [];
        if (roles.length > 0) {
          roles.forEach((row) => {
            options.push({ key: row.value, value: row.value })
          })
        }
        break;
      case 'assignedTo':
        let associates = selectData.associates || [];
        if (associates.length > 0) {
          associates.forEach((row) => {
            options.push({ key: row.id, value: row.name })
          })
        }
        break;
      default:
        break;
    }

    return options;
  }, [selectData])

  // Render a multi-select box
  return (
    <FormControl
      componentClass="select"
      placeholder="select"
      value={selected}
      onChange={e => {
        setFilter(e.target.value || undefined)
        setSelected(e.target.key);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={option.key} value={option.key}>
          {option.value}
        </option>
      ))}
    </FormControl>
  )
}

export default SelectColumnFilter;
