import { instance as axios } from "./http-common";
import jwt from "jsonwebtoken";
//const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export default {
  login,
  logout,
  getProfile,
  forgotPassword,
  verifyResetToken,
  resetPassword,
  getToken,
  getRole,
  verifyAuthToken,
  //currentUser: currentUserSubject.asObservable(),
  //get currentUserValue () { return currentUserSubject.value }
};

async function getProfile() {
  let res;
  let config = {};
  try {
    res = await axios.get(`user/profile`, config);
    /*if (res.status === 200 && res.data.auth === true) {
      let token = res.data.token;
      localStorage.setItem('access-token', token);
      return { status: true, token: token };
    }*/
  } catch (e) {
    console.log(e.response);
  }
  return true;
}

async function resetPassword(reset_token, password, confirm_password) {
  let res;
  let config = {};
  try {
    res = await axios.post(
      `auth/reset_password`,
      { reset_token, password, confirm_password },
      config
    );
    if (res.status === 200 && res.data.success === true)
      return { success: true };
    else return { success: false, msg: res.error.message };
  } catch (e) {
    console.log(e.response);
    return { success: false, msg: "Unable to verify reset token." };
  }
}

async function verifyResetToken(reset_token) {
  let res;
  let config = {};
  try {
    res = await axios.get(
      `auth/verify_reset_token`,
      { params: { reset_token } },
      config
    );
    if (res.status === 200 && res.data.success === true)
      return { success: true };
    else return { success: false, msg: res.error.message };
  } catch (e) {
    console.log(e.response);
    return { success: false, msg: "Unable to verify reset token." };
  }
}

async function forgotPassword(email) {
  let res;
  let config = {};
  try {
    res = await axios.post(`auth/forgot_password`, { email }, config);
    if (res.status === 200 && res.data.success === true)
      return { success: true };
    else return { success: false, msg: res.data.msg };
  } catch (e) {
    console.log(e.response);
    return { success: false, msg: "Unable to send password reset request." };
  }
}

async function login(username, password, remember) {
  // to simulate invalid login
  /*let config = {
    headers: {
      'x-mock-response-code': 401
    }
  }*/
  let config = { withCredentials: true };
  //let config = {};
  let res;
  try {
    res = await axios.post(
      `auth/signin`,
      { email: username, password: password, remember: remember, platform: 'web' },
      config
    );
    if (res.status === 200 && res.data.success === true) {
      let token = res.data.token;
      localStorage.setItem("access-token", token);
      localStorage.setItem("role", res.data.role);
      return { status: true, token: token, role: res.data.role };
    }
  } catch (e) {
    res = e.response;
    if (res.status === 401)
      return {
        status: false,
        statusCode: res.status,
        error: "Invalid username or password",
      };
    else return { status: false, statusCode: res.status };
  }
  return { status: false, statusCode: res.status };
  /*const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password })
  };

  return fetch(`${config.apiUrl}/users/authenticate`, requestOptions)
  .then(handleResponse)
  .then(user => {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem('currentUser', JSON.stringify(user));
    currentUserSubject.next(user);

    return user;
  }); */
}

function getToken() {
  return localStorage.getItem("access-token") || null;
}
function getRole() {
  return localStorage.getItem("role") || null;
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("access-token");
  localStorage.removeItem("role");
  //currentUserSubject.next(null);
}
function verifyAuthToken(token) {
  if (token && jwt.decode(token)) {
    const expiry = jwt.decode(token).exp;
    const now = new Date();
    return now.getTime() < expiry * 1000;
  }
}
