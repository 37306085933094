import React from "react";

import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";

import Card from "components/Card/Card.jsx";
import FormInputs from "components/FormInputs/FormInputs.jsx";
import { validate } from 'indicative/validator';
import Button from "components/CustomButton/CustomButton.jsx";
import orderService from 'services/orderService';
import { useEffect } from "react";
import { useHistory } from "react-router";
function OrderPage(props) {
  const [ordercreds, setorderCreds] = React.useState({
    id: "",
    sessionId: "",
    userId: "",
    vendorId: "",
    amount: "",
    currency: "",
    expiry: "",
    status: "",
    createdAt: "",
  }

  );
  const history = useHistory();
  const [msg, setMsg] = React.useState({ "success": "", "invalid": "", "buttonDisabled": true });
  const [validatorMessage, setValidatorMessage] = React.useState(null);

  useEffect(() => {
    const id = props.match.params.orderId;
    orderService.getOrder(id).then((res) => {
      if (res.success === true) {
        setorderCreds({
          ...ordercreds,
          id: res.order.id,
          sessionId: res.order.sessionId,
          userId: res.order.userId,
          vendorId: res.order.vendorId,
          currency: res.order.currency,
          amount: res.order.amount,
          expiry: res.order.expiry !== null ? (new Date(res.order.expiry).toISOString().slice(0, 10)) : "-",
          status: res.order.status,
        });
      } else {
        setMsg({ ...msg, success: "", invalid: "Couldn't load order data" });
        setValidatorMessage("");
      }
    });
  }, []);

  const handleChange = e => {
    setorderCreds({
      ...ordercreds,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const rules = {
      amount: 'required',
      currency: 'required',
      status: 'required',
    };
    const messages = {
      required: (field) => `${field.charAt(0).toUpperCase() + field.slice(1).replace(/([a-z])([A-Z-_])/g, '$1 $2')} is required`,
    };
    try {
      await validate(ordercreds, rules, messages);
      let params = {
        currency: ordercreds.currency,
        amount: ordercreds.amount,
        status: ordercreds.status,
      };
      let res = await orderService.updateOrder(props.match.params.orderId, params);
      if (res.success === true) {
        setValidatorMessage(null);
        setMsg({ ...msg, success: "Order Updated Successfully!", invalid: "" });
        setTimeout(() => history.push("/admin/orders"), 3000);
      }
      else {
        setValidatorMessage(null);
        setMsg({ ...msg, success: "", invalid: "Order was not updated!" });
      }
    } catch (e) {
      console.log(e);
      setMsg({ ...msg, invalid: "", success: "" });
      setValidatorMessage(e[0].message);
    }

  };

  return (
    <div className="main-content" >
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title="Edit Order Details"
              content={
                <form>
                  <div>
                    {validatorMessage && (
                      <div
                        className="alert alert-danger mt-2"
                        style={{
                          display: validatorMessage ? 'block' : 'none',
                        }}
                        role="alert"
                      >
                        {' '}
                        {validatorMessage}
                      </div>
                    )}
                  </div>
                  <div>
                    {msg.invalid && (
                      <div
                        className="alert alert-danger mt-2"
                        style={{
                          display: msg.invalid ? 'block' : 'none',
                        }}
                        role="alert"
                      >
                        {' '}
                        {msg.invalid}
                      </div>
                    )}
                  </div>
                  <div>
                    {msg.success && (
                      <div
                        className="alert alert-success mt-2"
                        style={{
                          display: msg.success ? 'block' : 'none',
                        }}
                        role="alert"
                      >
                        {' '}
                        {msg.success}
                      </div>
                    )}
                  </div>
                  <FormInputs
                    ncols={["col-md-5", "col-md-5"]}
                    proprieties={[
                      {
                        label: "Order ID",
                        name: "id",
                        type: "text",
                        bsClass: "form-control",
                        value: ordercreds.id,
                        disabled: true,
                      },
                      {
                        label: "Session ID",
                        name: "sessionId",
                        type: "text",
                        bsClass: "form-control",
                        defaultValue: ordercreds.sessionId,
                        disabled: true,
                      },
                    ]} />
                  <FormInputs
                    ncols={["col-md-6", "col-md-6"]}
                    proprieties={[
                      {
                        label: "Amount",
                        type: "text",
                        name: "amount",
                        bsClass: "form-control",
                        placeholder: "Amount",
                        value: ordercreds.amount,
                        onChange: (e) => handleChange(e)
                      },
                      {
                        label: "Currency",
                        type: "text",
                        name: "currency",
                        bsClass: "form-control",
                        value: ordercreds.currency,
                        onChange: (e) => handleChange(e)
                      },
                    ]}
                  />
                  <FormInputs
                    ncols={["col-md-4", "col-md-4"]}
                    proprieties={[
                      {
                        label: "Expiry",
                        type: "date",
                        name: "expiry",
                        value: ordercreds.expiry,
                        disabled: true,
                      },
                      {
                        label: "Status",
                        type: "text",
                        name: "status",
                        value: ordercreds.status,
                        onChange: (e) => handleChange(e)
                      },
                    ]}></FormInputs>

                  <Button bsStyle="primary" pullRight fill type="submit" onClick={handleSubmit}>
                    Update Order
                    </Button>
                  <div className="clearfix" />
                </form>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div >
  );
}


export default OrderPage;
