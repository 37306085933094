import React from 'react';
import FormControl from 'react-bootstrap/lib/FormControl';

function ColumnFilter({
  column: {
    filterValue,
    preFilteredRows,
    setFilter
  }}) {

  return (
    <FormControl
    type="text"
    value={filterValue || ''}
    onChange={e => {
      setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
    }}
    placeholder={`Search...`}
    />
  )
}

export default ColumnFilter;
