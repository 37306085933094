import axios from "axios";

export const instance = axios.create({
  //baseURL: "https://bb57f4d7-d2de-4998-af84-2994ac1b4c8f.mock.pstmn.io",
  baseURL: "https://demo-api.dentskan.com/api",
  //baseURL: "https://dentskan.biz/api",
  //baseURL: "http://localhost:8080/api",
  headers: {
    "Content-type": "application/json",
  },
});

instance.interceptors.request.use(function (config) {
  if(localStorage.getItem("access-token") !== undefined) {
    config.headers.Authorization = "Bearer " + localStorage.getItem("access-token");
  }

  return config;
});
