import { instance as axios } from "./http-common";

export default {
  vendors,
  addVendor,
  vendor,
  updateVendor,
};

async function vendors(size, page, sortBy, filters) {
  if (sortBy && sortBy.length > 0) {
    var order = sortBy[0].desc ? 'desc' : 'asc';
    sortBy = sortBy[0].id;
  }

  let params = {
    size,
    page,
    sortBy,
    order,
  }
  if (filters) {
    filters.forEach(function (v) {
      params[v.id] = v.value;
    })
  }
  let res;
  let config = {
    params
  };
  try {
    res = await axios.get(
      `vendors`,
      config
    );
    if (res.status === 200 && res.data.success === true)
      return { success: true, vendors: res.data.vendors, count: res.data.count };
    else return { success: false, msg: res.error.message };
  } catch (e) {
    console.log(e.response);
    return { success: false, msg: "Unable to fetch vendors." };
  }
}
async function addVendor(vendor) {
  let params = {};

  let res;
  let config = {
    params
  };

  try {
    res = await axios.post(
      `vendors`,
      vendor,
      config
    );
    if (res.status === 200 && res.data.success === true)
      return { success: true };
    else return { success: false, msg: res.error.message };
  } catch (e) {
    console.log(e.response);
    return { success: false, msg: "Unable to add vendor." };
  }
}
async function updateVendor(vendorId, vendorData) {
  let params = {};

  let res;
  let config = {
    params
  };

  try {
    res = await axios.post(
      `vendors/${vendorId}`, vendorData,
      config
    );
    if (res.status === 200 && res.data.success === true)
      return { success: true };
    else return { success: false, msg: res.error.message };
  } catch (e) {
    console.log(e.response);
    return { success: false, msg: "Unable to update vendor." };
  }
}

async function vendor(vendorId) {
  let params = {};

  let res;
  let config = {
    params
  };

  try {
    res = await axios.get(
      `vendors/${vendorId}`,
      config
    );
    if (res.status === 200 && res.data.success === true)
      return {
        success: true,
        vendor: res.data.vendor,
        orderCount: res.data.orderCount,
        deviceCount: res.data.deviceCount,
        sessionCount: res.data.sessionCount,
        userCount: res.data.userCount,
      };
    else return { success: false, msg: res.error.message };
  } catch (e) {
    console.log(e.response);
    return { success: false, msg: "Unable to fetch vendors." };
  }
}

