import React from "react"

function FilterInput ({column, selectData}) {
  return (
    <div className="table-filters">
      {column.canFilter && column.render("Filter", selectData)}
    </div>
  )
}

export default FilterInput;
