import { instance as axios } from "./http-common";

export default {
  deleteComment,
};

async function deleteComment(commentId) {
  let res;
  let config = {};
  try {
    res = await axios.delete(
      `comment/${commentId}`,
      config
    );
    if (res.status === 200 && res.data.success === true)
      return { success: true };
    else return { success: false, msg: res.error.message };
  } catch (e) {
    console.log(e.response);
    return { success: false, msg: "Unable to delete comment." };
  }
}