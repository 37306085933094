import React, { createContext, useState } from "react";
import authService from "services/authService.jsx";

function initialState() {
  const token = authService.getToken() || null;
  const role = authService.getRole() || null;
  const initialData = { name: null, email: null, token: token, role: role };
  if (token === null) { initialData.loggedIn = false; }
  else if (!authService.verifyAuthToken(token)) {
    authService.logout();
    initialData.loggedIn = false;
  }
  else initialData.loggedIn = true;
  return initialData;
}

/*const setAuthData = (data) => {
  setAuth({ ...auth, ...data });
};*/

const authContext = createContext();
const authDispatchContext = createContext();

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(initialState());
  // we will use loading later

  /*const isAuthenticated = () => {
    return auth.token !== null;
  }*/

  const setAuthData = (data) => {
    setAuth({ ...auth, ...data });
  };
  // a function that will help us to add the user data in the auth;

  return (
    <authContext.Provider value={{ auth }}>
      <authDispatchContext.Provider value={{ setAuthData }}>
        {children}
      </authDispatchContext.Provider>
    </authContext.Provider>
  );
};

export { AuthProvider, authContext, authDispatchContext };
