import React, { useContext } from "react";

import Nav from "react-bootstrap/lib/Nav";
import NavDropdown from "react-bootstrap/lib/NavDropdown";
import MenuItem from "react-bootstrap/lib/MenuItem";

import { useHistory } from "react-router";
import authService from "services/authService.jsx";
import { authDispatchContext } from "state/AuthProvider";
import { can_navigate } from "../Can.jsx";
function HeaderLinks(props) {
  const history = useHistory();
  const { setAuthData } = useContext(authDispatchContext);

  function logout(e) {
    authService.logout(e);
    setAuthData({ name: null, email: null, token: null, loggedIn: false });
    history.push("/auth/login");
  }

  const handleClick = e => {
    can_navigate("My Details") && history.push("/admin/settings/edit");
  }

  return (
    <div>
      <Nav pullRight>
        <NavDropdown
          eventKey={4}
          title={
            <div>
              <i className="fa fa-bars" />
              <p className="hidden-md hidden-lg">
                More
                <b className="caret" />
              </p>
            </div>
          }
          noCaret
          id="basic-nav-dropdown-3"
          bsClass="dropdown-with-icons dropdown"
        >
          <MenuItem eventKey={4.3} onClick={handleClick}>
            <i className="pe-7s-tools" /> Settings
          </MenuItem>
          <MenuItem eventKey={4.5} onClick={logout}>
            <div className="text-danger">
              <i className="pe-7s-close-circle" /> Log out
            </div>
          </MenuItem>
        </NavDropdown>
      </Nav>
    </div>
  );
}

export default HeaderLinks;
