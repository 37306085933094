import React, { useContext, useEffect } from 'react';

import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import FormGroup from "react-bootstrap/lib/FormGroup";
import ControlLabel from "react-bootstrap/lib/ControlLabel";
import FormControl from "react-bootstrap/lib/FormControl";

import Card from 'components/Card/Card';

import Button from 'components/CustomButton/CustomButton';
import Checkbox from 'components/CustomCheckbox/CustomCheckbox';
import logo from 'assets/brand/icon-white.svg';
import authService from 'services/authService';
import { Link } from 'react-router-dom';
import { authContext, authDispatchContext } from 'state/AuthProvider';
import { validate } from 'indicative/validator';
import { useHistory } from 'react-router';

let role = null;

function LoginPage(props) {
  const [creds, setCreds] = React.useState({
    email: '',
    password: '',
    invalidLogin: false,
    remember: false,
  });
  const [validatorMessage, setValidatorMessage] = React.useState(null);
  const [cardHidden, setCardHidden] = React.useState(true);
  const { setAuthData } = useContext(authDispatchContext);
  const { auth } = useContext(authContext);
  const history = useHistory();

  const toggleRemember = (e) => {
    setCreds({
      ...creds,
      [e.target.name]: e.target.checked,
    });
  };

  const handleCredsChange = (e) => {
    setValidatorMessage(null);
    setCreds({
      ...creds,
      [e.target.name]: e.target.value,
    });
  };

  const login = async (e) => {
    e.preventDefault();
    const rules = {
      email: 'required|email',
      password: 'required|min:4|max:40',
    };
    const messages = {
      required: (field) => `${field.charAt(0).toUpperCase() + field.slice(1).replace(/([a-z])([A-Z-_])/g, '$1 $2')} is required`,
      'email.email': 'Please enter a valid email address',
      'password.min': 'Password is too short',
      'password.max': 'Password is too long',
    };
    try {
      const response = await validate(creds, rules, messages);
      authService
        .login(creds.email, creds.password, creds.remember)
        .then((res) => {
          setCreds((prevState) => ({ ...prevState, invalidLogin: !res.status }));
          if (res.status) {
            setAuthData({
              email: creds.email,
              token: res.token,
              loggedIn: true,
              role: res.role,
            });
            if (res.role === "Super Admin") {
              history.push('/admin/users');
            }
            else {
              history.push('/admin/orders');
            }
          }
        });
    } catch (e) {
      console.log(e);
      setValidatorMessage(e[0].message);
    }
  };

  useEffect(() => {
    let mounted = true;
    setTimeout(() => {
      if (mounted) setCardHidden(false);
    }, 700);
    return () => (mounted = false);
  });

  return (
    <>
      <Grid>
        <Row>
          <Col md={4} sm={6} mdOffset={4} smOffset={3}>
            <form onSubmit={login}>
              <Card
                hidden={cardHidden}
                content={(
                  <div>
                    <div className="login-icon">
                      <img src={logo} alt="react-logo" />
                    </div>
                    <FormGroup>
                      {validatorMessage && (
                        <div
                          className="alert alert-danger mt-2"
                          style={{
                            display: validatorMessage ? 'block' : 'none',
                          }}
                          role="alert"
                        >
                          {' '}
                          {validatorMessage}
                        </div>
                      )}

                      {creds.invalidLogin === true && (
                        <div className="alert alert-danger" role="alert">
                          Invalid username or password
                        </div>
                      )}
                      <ControlLabel>Email address</ControlLabel>
                      <FormControl
                        placeholder="Enter email"
                        type="email"
                        name="email"
                        onChange={handleCredsChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>Password</ControlLabel>
                      <FormControl
                        placeholder="Password"
                        type="password"
                        autoComplete="off"
                        name="password"
                        onChange={handleCredsChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Checkbox
                        number="1"
                        label="Remember me"
                        name="remember"
                        onChange={toggleRemember}
                      />
                    </FormGroup>
                  </div>
                )}
                legend={(
                  <Button bsStyle="primary" fill wd type="submit">
                    Login
                  </Button>
                )}
                ftTextCenter
              />
              <div>
                <Link to="/auth/forgotpassword">Forgot Password?</Link>
              </div>
            </form>
          </Col>
        </Row>
      </Grid>
    </>
  );
}

export default LoginPage;
export { role };
