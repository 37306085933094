import React, { useEffect, useState } from "react";
// react component for creating dynamic tables
import { CSVReader } from 'react-papaparse';
import NotificationSystem from 'react-notification-system';
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import Modal from "react-bootstrap/lib/Modal";
import Form from "react-bootstrap/lib/Form";
import FormGroup from "react-bootstrap/lib/FormGroup";
import ControlLabel from "react-bootstrap/lib/ControlLabel";
import FormControl from "react-bootstrap/lib/FormControl";
import ButtonToolbar from "react-bootstrap/lib/ButtonToolbar";
import { useHistory } from 'react-router';
import { validate } from 'indicative/validator';
import Card from "components/Card/Card.jsx";
import Table from "components/Table/Table.jsx";
import ColumnFilter from "components/Table/ColumnFilter.jsx";
import SelectColumnFilter from "components/Table/SelectColumnFilter.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import accService from 'services/accessoriesService';
import vendorService from 'services/vendorService';
import debounce from 'components/Debounce';
import { deviceStatuses } from '../variables/Variables.jsx';
import Can from "../components/Can.jsx";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import Select from 'react-select';

function Accessories(props) {
  const notificationSystem = React.createRef();
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [deviceToDelete, setDeviceToDelete] = useState(null);
  const [item, setItem] = useState({});
  const [obj, setObj] = useState({});
  const [showDownloadButton, setshowDownloadButton] = useState(false);
  const [showAccessoriesCreate, setShowAccessoriesCreate] = useState(false);
  const [msg, setMsg] = React.useState({ "success": "", "invalid": "", "buttonDisabled": true });
  const [showCSV, setshowCsv] = useState(false);
  const [showBatch, setShowBatch] = useState(false);
  const [csverrors, setCsverrors] = useState([]);
  const [Accessory_count, setAccessory_count] = useState(0);
  const [validatorMessage, setValidatorMessage] = React.useState(null);
  const initialDeviceCreds = {
    deviceId: "",
    serialNumber: "",
    status: "Inactive",
    vendorId: "",
  };
  const [deviceCreds, setdeviceCreds] = React.useState(initialDeviceCreds);

  const [batchAssignmentData, setBatchAssignmentData] = React.useState({
    batchCount: 0,
    batchVendorId: undefined,
  })

  const handleClose = () => setShow(false);

  const [data, setData] = React.useState([]);
  const [showDelAccessoryModal, setShowDelAccessoryModal] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [loadingCSV, setLoadingCSV] = React.useState(false);
  const [loadingBatch, setLoadingBatch] = React.useState(false);
  const [vendors, setVendors] = React.useState([]);
  const [selectData, setSelectData] = React.useState({
    statuses: deviceStatuses,
    vendors,
  });

  function fetchAccessories({ pageSize, pageIndex, sortBy, filters }) {
    setLoading(true);
    accService.accessories(pageSize, pageIndex, sortBy, filters).then((res) => {
      setLoading(false);
      if (res.success) {
        let dataTable = res.devices;
        setData(
          dataTable.map((prop) => {
            return {
              id: prop.id,
              serialNumber: prop.serialNumber,
              status: prop.status,
              lastUsed: prop.lastUsed !== null ? (new Date(prop.lastUsed).toISOString().slice(0, 10)) : "-",
              vendor: prop.vendor ? prop.vendor.name : "Unassigned",
              actions: (
                <div className="actions-right">
                  <Button
                    bsStyle="primary"
                    simple
                    icon
                    onClick={(e) => {
                      let obj = dataTable.find((o) => o.id === prop.id);
                      setItem(obj);
                      setShow(true);
                    }}
                  >
                    <i className="fa fa-eye" />
                  </Button>{" "}
                  {Can("accessories-page:delete",) && <Button
                    bsStyle="primary"
                    simple
                    icon
                    onClick={(e) => {
                      let obj = dataTable.find((o) => o.id === prop.id);
                      setDeviceToDelete(obj.serialNumber);
                      setShowDelAccessoryModal(true);
                    }}
                  >
                    <i className="fa fa-trash" />
                  </Button>}
                </div>

              ),
            };
          }),
        );

        setPageCount(Math.ceil(res.count / pageSize));
        setTotalCount(res.count);
      }
    });
  }

  const fetchData = React.useCallback(debounce(fetchAccessories, 500), []);

  let clmns = [
    {
      Header: "Device ID",
      accessor: "serialNumber",
      Filter: ColumnFilter,
    },
    {
      Header: "Status",
      accessor: "status",
      Filter: SelectColumnFilter,
    },
    {
      Header: "Vendor",
      accessor: "vendor",
      disableSortBy: true,
      Filter: SelectColumnFilter,
    },
    {
      Header: "Last Used",
      accessor: "lastUsed",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: "Actions",
      accessor: "actions",
      disableSortBy: true,
      disableFilters: true,
    }
  ];

  if (!Can("accessories-page:create")) {
    clmns = clmns.filter(row => {
      return row.accessor !== "vendor";
    })
  }
  const columns = React.useMemo(
    () =>
      clmns,
    []
  )

  useEffect(() => {
    //fetchAccessories({});
    if (Can("accessories-page:create")) {
      vendorService.vendors().then((res) => {
        if (res.success) {
          const v = res.vendors.map((vendor) => {
            return { label: vendor.name, value: vendor.id };
          })
          setVendors(v);
          setSelectData({
            ...selectData,
            vendors: res.vendors,
          });
        }
      })
    }
  }, []);

  const handleAddAccessory = (e) => {
    setMsg({ ...msg, success: "", invalid: "" });
    setValidatorMessage(null);
    setShowAccessoriesCreate(true);
  }

  const handleBatchAssignmentChange = (e) => {
    setBatchAssignmentData({
      ...batchAssignmentData,
      [e.target.name]: e.target.value
    });
  };

  const handleChange = (e) => {
    setMsg({ ...msg, success: "", invalid: "" });
    setValidatorMessage(null);
    setdeviceCreds({
      ...deviceCreds,
      [e.target.name]: e.target.value,
    });
  };

  const downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob(csverrors,
      { type: 'text/plain;charset=utf-8' });
    element.href = URL.createObjectURL(file);
    element.download = "error.txt";
    document.body.appendChild(element);
    element.click();
  };
  const isValidSerialNumber = sn => {
    if (/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}/.test(sn)) {
      return true;
    }
    return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const rules = {
      serialNumber: 'required',
      status: 'required',
    };
    const messages = {
      required: (field) => `${(field.charAt(0).toUpperCase() + field.slice(1)).replace(/([a-z])([A-Z-_])/g, '$1 $2')} is required`,
    };
    try {
      const resp = await validate(deviceCreds, rules, messages);

      accService.addAccessory(deviceCreds).then((res) => {
        if (res.success === true) {
          setValidatorMessage(null);
          setMsg({ ...msg, success: "Accessory Added Successfully!", invalid: "" });
          setTimeout(() => setShowAccessoriesCreate(false), 3000);
        }
        else {
          setValidatorMessage(null);
          setMsg({ ...msg, success: "", invalid: res.msg });

        }
      }
      );
    } catch (e) {
      console.log(e);
      setMsg({ ...msg, invalid: "", success: "" });
      setValidatorMessage(e[0].message);
    }
  };

  const handleCSVerror = (e, file, inputElem, reason) => {
    setMsg({ ...msg, invalid: "Sorry, couldn't load CSV. Try again!", success: "" });
  }

  const handleonDrop = _data => {
    if (_data.size > (1024 * 1024)) /* 1MB */ {
      setMsg({ ...msg, invalid: "Please upload file within 1MBs", success: "" });
      setValidatorMessage(null);
    }
    let obj = _data.filter((prop) => {
      if (prop.data[0] === "") { return false; }
      return true;
    }).map((prop) => {
      return {
        serialNumber: prop.data[0],
        status: "Inactive",
        // lastUsed: prop.data[2] !== "" ? (new Date(prop.data[2]).toISOString().slice(0, 10)) : "-",
        // createdAt: prop.data[3] !== "" ? (new Date(prop.data[3]).toISOString().slice(0, 10)) : "-",
        // updatedAt: prop.data[4] !== "" ? (new Date(prop.data[4]).toISOString().slice(0, 10)) : "-",
        // vendorId: 1,

      }
    });
    setObj(obj);
    if (obj.length === 0) {
      setMsg({ ...msg, invalid: "Sorry, your file is empty!", success: "" });
    }
    else {
      setAccessory_count(obj.length);
      setMsg({ ...setMsg, buttonDisabled: false });

    }
  };

  const handleOnRemoveFile = data => {
    setMsg({ ...msg, buttonDisabled: true, success: "", invalid: "" });
    setAccessory_count(0);
    setshowDownloadButton(false);
  };
  const handleDeviceDelete = e => {
    e.preventDefault();
    accService.deleteAccessory(deviceToDelete).then((res) => {
      const notification = notificationSystem.current;
      if (res.success === true) {
        notification.addNotification({
          message: 'Device deleted successfully!',
          level: 'success'
        });
        setTimeout(() => notification.clearNotifications(), 3500);
        setTimeout(() => setShowDelAccessoryModal(false), 3500);
        setTimeout(() => fetchAccessories({}), 4500);
      }
      else {
        notification.addNotification({
          message: res.msg,
          level: 'error'
        });
      }

    })
  }
  const handleBatchAssignment = async (e) => {
    e.preventDefault();
    setLoadingBatch(true);
    setMsg({ success: "", invalid: "" });
    if (batchAssignmentData.batchCount < 1) {
      setMsg({ success: "", invalid: "Device count needs to be atleast 1." });
      return;
    }

    if (batchAssignmentData.batchVendorId === undefined) {
      setMsg({ success: "", invalid: "Vendor is a required field." });
      return;
    }

    accService.batchAssign(batchAssignmentData.batchCount, batchAssignmentData.batchVendorId).then((res) => {
      if (res.success === true) {
        setLoadingBatch(false);
        setMsg({ ...msg, success: "Accessories assigned successfully!", invalid: "" });
        setTimeout(() => setShowBatch(false), 3000);
        setTimeout(() => history.push("/admin/accessories"), 3000);
      } else {
        setValidatorMessage(null);
        setMsg({ ...msg, success: "", invalid: res.msg });
      }
    })

    setLoadingBatch(false);
  };

  const handleBulkUpload = async (e) => {
    e.preventDefault();
    setLoadingCSV(true);
    if (obj.length === 0) {
      setMsg({ success: "", invalid: "No accessories to upload" });
      setValidatorMessage(null);
      setLoadingCSV(false);
    } else {
      let errors = [];
      let duplicate_sn = [];
      for (var i = 0; i < obj.length; i++) {
        if (isValidSerialNumber(obj[i].serialNumber)) {
          if (duplicate_sn.indexOf(obj[i].serialNumber) !== -1) {
            errors.push("Duplicate serial number " + obj[i].serialNumber + " on line " + (i + 1) + "\n");
          }
          else {
            duplicate_sn.push(obj[i].serialNumber);
          }
        }
        else {
          errors.push("Invalid serial number " + obj[i].serialNumber + " on line " + (i + 1) + "\n");
        }
      }
      setCsverrors(errors);
      if (errors.length === 0) {
        await accService.addAccessories(obj).then((res) => {
          if (res.success === true) {
            setValidatorMessage(null);
            setMsg({ ...msg, success: "Accessories Added Successfully!", invalid: "" });
            setTimeout(() => setshowCsv(false), 3000);
            setTimeout(() => history.push("/admin/accessories"), 3000);
          }
          else {
            setValidatorMessage(null);
            setMsg({ ...msg, success: "", invalid: res.msg });
          }
        });
      }
      else if (errors.length === 1 || errors.length === 2) {
        setValidatorMessage(null);
        setMsg({ ...msg, success: "", invalid: errors.join(',') });
      }
      else {
        setValidatorMessage(null);
        setMsg({ ...msg, success: "", invalid: "File contains invalid/duplicate device IDs. Please download errors file." });
        setshowDownloadButton(true);
      }
    }
    setLoadingCSV(false);
  };
  return (
    <div className="main-content">
      <Grid fluid>
        <Row className="ds-table-row">
          <Col md={12}>
            <Card
              title={props.name}
              content={
                <Table
                  columns={columns}
                  data={data}
                  fetchData={fetchData}
                  loading={loading}
                  pageCount={pageCount}
                  totalCount={totalCount}
                  selectData={selectData}
                />
              }
            />
          </Col>
        </Row>
        {Can("accessories-page:create") && <ButtonToolbar className="pull-right">
          <Button bsStyle="primary" wd fill onClick={() => {
            setshowCsv(true);
            setMsg({ ...msg, invalid: "", success: "" });
          }}>
            <Loader visible={loadingCSV} type="ThreeDots" color="#ffffff" height={10} width={80} />
            {!loadingCSV && <span>Upload CSV</span>}
          </Button>
          <Button bsStyle="primary" wd fill onClick={handleAddAccessory}>
            <span>Add Accessories</span>
          </Button>
          <Button bsStyle="primary" wd fill onClick={() => { setShowBatch(true) }}>
            <Loader visible={loadingBatch} type="ThreeDots" color="#ffffff" height={10} width={80} />
            {!loadingBatch && <span>Batch Assignment</span>}
          </Button>
        </ButtonToolbar>}
      </Grid>
      <Modal show={showAccessoriesCreate} onHide={() => setShowAccessoriesCreate(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Accessory</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form horizontal>
            <div>
              {validatorMessage && (
                <div
                  className="alert alert-danger mt-2"
                  style={{
                    display: validatorMessage ? 'block' : 'none',
                  }}
                  role="alert"
                >
                  {' '}
                  {validatorMessage}
                </div>
              )}
            </div>
            <div>
              {msg.invalid && (
                <div
                  className="alert alert-danger mt-2"
                  style={{
                    display: msg.invalid ? 'block' : 'none',
                  }}
                  role="alert"
                >
                  {' '}
                  {msg.invalid}
                </div>
              )}
            </div>
            <div>
              {msg.success && (
                <div
                  className="alert alert-success mt-2"
                  style={{
                    display: msg.success ? 'block' : 'none',
                  }}
                  role="alert"
                >
                  {' '}
                  {msg.success}
                </div>
              )}
            </div>
            <FormGroup controlId="formHorizontaldeviceId">
              <Col componentClass={ControlLabel} sm={3}>
                Serial Number
              </Col>
              <Col sm={9}>
                <FormControl type="text" name="serialNumber" onChange={handleChange} placeholder="Device ID" />
              </Col>
            </FormGroup>
            <FormGroup controlId="formControlsSelect2">
              <Col componentClass={ControlLabel} sm={3}>
                Status
              </Col>
              <Col sm={9}>
                <FormControl disabled componentClass="select" name="status" onChange={handleChange} value={deviceCreds.status} placeholder="Status">
                  <option value="Inactive">Inctive</option>
                  <option value="Active">Active</option>
                  <option value="Shipped">Active</option>
                  <option value="RMA">Active</option>
                </FormControl></Col>
            </FormGroup>
            <FormGroup controlId="formHorizontalCity">
              <Col componentClass={ControlLabel} sm={3}>
                Vendor
              </Col>
              <Col sm={9}>
                <Select
                  menuPlacement="top"
                  maxMenuHeight={250}
                  isSearchable="true"
                  placeholder="Vendor"
                  name="vendorId"
                  options={vendors}
                  onChange={(vendorId) => {
                    setdeviceCreds({
                      ...deviceCreds,
                      vendorId: vendorId.value
                    });
                  }}

                />
              </Col>
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" bsStyle="primary" wd fill
            onClick={handleSubmit}>Add Accessory</Button>
          <Button bsStyle="default" onClick={() => { setShowAccessoriesCreate(false); setMsg({ success: "", invalid: "" }); ; setdeviceCreds(initialDeviceCreds); setValidatorMessage(null); }}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>3 Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={3}>
              <label>Device ID: </label>
            </Col>
            <Col md={9}>
              <span>{item.serialNumber}</span>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <label>Status: </label>
            </Col>
            <Col md={9}>
              <span>{item.status}</span>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <label>Last Used: </label>
            </Col>
            <Col md={9}>
              <span>{item.lastUsed === null ? "-" : item.lastUsed}</span>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="default" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showBatch} onHide={() => { setShowBatch(false) }}>
        <Modal.Header closeButton>
          <Modal.Title>Device Assignment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div>
              {msg.invalid && (
                <div
                  className="alert alert-danger mt-2"
                  style={{
                    display: msg.invalid ? 'block' : 'none',
                  }}
                  role="alert"
                >
                  {' '}
                  {msg.invalid}
                </div>
              )}
            </div>
            <div>
              {msg.success && (
                <div
                  className="alert alert-success mt-2"
                  style={{
                    display: msg.success ? 'block' : 'none',
                  }}
                  role="alert"
                >
                  {' '}
                  {msg.success}
                </div>
              )}
            </div>
          </Row>

          <Form horizontal>
            <FormGroup controlId="formHorizontalCity">
              <Col componentClass={ControlLabel} sm={3}>
                Device Count
              </Col>
              <Col sm={9}>
                <FormControl type="number" name="batchCount" onChange={handleBatchAssignmentChange} placeholder="Count" />
              </Col>
            </FormGroup>

            <FormGroup controlId="formHorizontalCity">
              <Col componentClass={ControlLabel} sm={3}>
                Vendor
              </Col>
              <Col sm={9}>
                <Select
                  menuPlacement="top"
                  maxMenuHeight={250}
                  isSearchable="true"
                  placeholder="Vendor"
                  name="batchVendorId"
                  options={vendors}
                  onChange={(vendorId) => {
                    setBatchAssignmentData({
                      ...batchAssignmentData,
                      batchVendorId: vendorId.value
                    });
                  }}

                />
              </Col>
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" bsStyle="primary" fill onClick={handleBatchAssignment}>Assign</Button>
          <Button bsStyle="default" onClick={() => {
            setShowBatch(false);
            setMsg({ success: "", invalid: "" });
            setValidatorMessage(null);
            setBatchAssignmentData({ batchVendorId: undefined, batchCount: 0 });
          }}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showCSV} onHide={() => setshowCsv(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Accessories via CSV file</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form horizontal>
            <div>
              {msg.invalid && (
                <div
                  className="alert alert-danger mt-2"
                  style={{
                    display: msg.invalid ? 'block' : 'none',
                  }}
                  role="alert"
                >
                  {' '}
                  {msg.invalid}
                </div>
              )}
            </div>
            <div>
              {msg.success && (
                <div
                  className="alert alert-success mt-2"
                  style={{
                    display: msg.success ? 'block' : 'none',
                  }}
                  role="alert"
                >
                  {' '}
                  {msg.success}
                </div>
              )}
            </div>
            <CSVReader
              onDrop={handleonDrop} onError={handleCSVerror}
              addRemoveButton
              onRemoveFile={handleOnRemoveFile}>
              <span>Drop CSV file here or click to upload.</span>
            </CSVReader><br /><br />
            <Row>
              <Col md={3}>
                <label>Accessory Count: </label>
              </Col>
              <Col md={9}>
                <span>{Accessory_count}</span>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {showDownloadButton ? <Button onClick={downloadTxtFile} fill>Download Errors File</Button> : null}
          <Button type="submit" bsStyle="primary" fill onClick={handleBulkUpload} disabled={msg.buttonDisabled}>Upload</Button>
          <Button bsStyle="default" onClick={() => { setshowCsv(false); setMsg({ success: "", invalid: "" }); setValidatorMessage(null); setshowDownloadButton(false); setAccessory_count(0); }}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDelAccessoryModal} onHide={() => setShowDelAccessoryModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title><label>Confirm</label></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <NotificationSystem ref={notificationSystem} />
            <Row>
              <Col md={9}>
                <span>Do you want to delete device with serial number <b>{deviceToDelete}</b> ?</span>
              </Col>

            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" bsStyle="primary" fill onClick={handleDeviceDelete}>Delete</Button>
          <Button bsStyle="default" onClick={() => { setMsg({ success: "", invalid: "" }); setShowDelAccessoryModal(false); }}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Accessories;
