//import Dashboard from 'views/Dashboard';
import Vendor from 'views/vendor';
import Order from 'views/Order';
import Users from 'views/Users';
import Sessions from 'views/Sessions';
import Vendors from 'views/Vendors';
import Session from 'views/Session';
import Scan from 'views/Scan';
import Accesories from 'views/Accessories';
import Orders from 'views/Orders';
import UserPage from 'views/Pages/UserPage';
import LoginPage from 'views/Pages/LoginPage';
import ForgotPassword from 'views/Pages/ForgotPasswordPage';
import ResetPassword from 'views/Pages/ResetPasswordPage';
import AdminPage from 'views/Pages/AdminPage';
import VendorPage from './views/Pages/VendorPage';
import OrderPage from './views/Pages/OrderPage';

const routes = [
  {
    path: '/vendor/:vendorId',
    layout: '/admin',
    name: 'Vendor',
    icon: 'fa fa-columns',
    component: Vendor,
  },
  {
    path: '/users',
    layout: '/admin',
    name: 'Users',
    icon: 'fa fa-users',
    component: Users,
  },
  {
    path: '/accessories',
    layout: '/admin',
    name: 'Accesories',
    icon: 'fa fa-server',
    component: Accesories,
  },
  {
    path: '/vendors',
    layout: '/admin',
    name: 'Vendors',
    icon: 'fa fa-wrench',
    component: Vendors,
  },
  {
    path: '/orders',
    layout: '/admin',
    name: 'Orders',
    icon: 'fa fa-shopping-cart',
    component: Orders,
  },
  {
    collapse: true,
    path: '/sessions',
    layout: '/admin',
    name: 'Sessions',
    state: 'openSessions',
    icon: 'fa fa-archive',
    views: [
      {
        path: '/active-sessions',
        layout: '/admin',
        name: 'Active Sessions',
        mini: 'AS',
        component: Sessions,
      },
      {
        path: '/completed-sessions',
        layout: '/admin',
        name: 'Completed Sessions',
        mini: 'CS',
        component: Sessions,
      },
    ]
  },
  {
    path: '/session/:sessionId/scan/:scanId',
    layout: '/admin',
    component: Scan,
    name: "Scan Details",
    hidden: true
  },
  {
    path: '/session/:sessionId',
    layout: '/admin',
    component: Session,
    name: "Session Details",
    hidden: true
  },
  {
    path: '/scan/:scanId',
    layout: '/admin',
    component: Scan,
    name: "User Scan",
    hidden: true
  },
  {
    collapse: true,
    path: '/pages',
    name: 'Pages',
    hidden: true,
    state: 'openPages',
    icon: 'pe-7s-gift',
    views: [
      {
        path: '/user/:userId/profile',
        layout: '/admin',
        name: 'User Page',
        mini: 'UP',
        component: UserPage,
      },
      {
        path: '/vendor/:vendorId/profile',
        layout: '/admin',
        name: 'Vendor Page',
        mini: 'VP',
        component: VendorPage,
      },
      {
        path: '/order/:orderId/edit',
        layout: '/admin',
        name: 'Order Page',
        mini: 'OP',
        component: OrderPage,
      },
      {
        path: '/order/:orderId',
        layout: '/admin',
        name: 'Order',
        mini: 'OP',
        component: Order,
      },
      {
        path: '/login',
        layout: '/auth',
        name: 'Login Page',
        mini: 'LP',
        component: LoginPage,
      },
      {
        path: '/forgotpassword',
        layout: '/auth',
        name: 'Forgot Password Page',
        mini: 'FP',
        component: ForgotPassword,
      },
      {
        path: '/resetpassword/:code',
        layout: '/auth',
        name: 'Reset Password Page',
        mini: 'RPP',
        component: ResetPassword,
      },
      {
        path: '/settings/edit',
        layout: '/admin',
        name: 'My Details',
        mini: 'AP',
        component: AdminPage,
      },


    ],
  },
];
export default routes;
