import React, { useState, useEffect } from "react";
// react component for creating dynamic tables
import Select from 'react-select';
import moment from 'moment';
import { orderStatuses, toastConfig } from 'variables/Variables.jsx';
import commentService from 'services/commentService';

import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import Modal from "react-bootstrap/lib/Modal";
import Form from "react-bootstrap/lib/Form";
import FormGroup from "react-bootstrap/lib/FormGroup";
import ControlLabel from "react-bootstrap/lib/ControlLabel";
import FormControl from "react-bootstrap/lib/FormControl";
import ButtonToolbar from "react-bootstrap/lib/ButtonToolbar";
import Glyphicon from "react-bootstrap/lib/Glyphicon";

import { ToastContainer, toast } from 'react-toastify';

import { validate } from 'indicative/validator';
import orderService from 'services/orderService';
import 'react-datetime/css/react-datetime.css';
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import ReactLoading from 'react-loading';

function Order(props) {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [auditloading, setauditLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [msg, setMsg] = React.useState({ "success": "", "invalid": "" });
  const [validatorMessage, setValidatorMessage] = React.useState(null);
  const [showShippingCreds, setShowShippingCreds] = React.useState(false);

  const handleClose = () => {
    setShow(false); setValidatorMessage(null);
    setMsg({ ...msg, success: "", invalid: "" })
  };
  const handleShow = () => {
    setShow(true); setValidatorMessage(null);
    setMsg({ ...msg, success: "", invalid: "" })
  };

  const [status, setStatus] = React.useState();
  const [comment, setComment] = React.useState('');
  const [data, setData] = React.useState({});
  const [auditData, setAuditData] = React.useState([]);
  const [orderCreds, setorderCreds] = React.useState({
    id: props.match.params.orderId,
    status: "",
    description: "",
    amount: "",
  });
  const [shippingCreds, setShippingCreds] = React.useState({});
  const [labelDimensions, setLabelDimensions] = React.useState({
    height: 5,
    width: 5,
    length: 5,
    weight: 2,
  });

  const handleLabelDimensionChange = (e) => {
    setValidatorMessage(null);
    setMsg({ ...msg, success: "", invalid: "" })
    setLabelDimensions({
      ...labelDimensions,
      [e.target.name]: e.target.value,
    });
  }

  const handleChange = (e) => {
    setValidatorMessage(null);
    setMsg({ ...msg, success: "", invalid: "" })
    setorderCreds({
      ...orderCreds,
      [e.target.name]: e.target.value,
    });
    if (e.target.value !== data[e.target.name]) {
      setDisableButton(false);
    }
  };

  const handleCommentDelete = (e, id) => {
    e.preventDefault();
    commentService.deleteComment(id).then((res) => {
      if (res.success === true) {
        //setMsg({ ...msg, success: "Comment has been deleted", invalid: "" });
        toast.success('Comment has been deleted.', toastConfig);
        setauditLoading(true);
        setTimeout(() => fetchAudits(), 300);
      }
      else {
        toast.warn('Error deleting comment.', toastConfig);
        //setMsg({ ...msg, success: "", invalid: "Comment was not deleted" });
      }
    })
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    const rules = {
      description: 'string|required',
    };
    const messages = {
      required: (field) => `${(field.charAt(0).toUpperCase() + field.slice(1)).replace(/([a-z])([A-Z-_])/g, '$1 $2')} is required`,
    };
    const error = [{ message: "Nothing to update." }];
    try {
      await validate(orderCreds, rules, messages);
      let payload = {
        description: orderCreds.description,
      }
      if (orderCreds.description === data.description) {
        throw error;
      }
      setLoading(true);
      orderService.updateOrder(orderCreds.id, payload).then((res) => {
        setLoading(false);
        if (res.success === true) {
          toast.success('Order has been updated.', toastConfig);
          setDisableButton(true);
          setValidatorMessage(null);
          //setMsg({ ...msg, success: "Order has been updated successfully!", invalid: "" });
          setTimeout(() => setShow(false), 3000);
          setLoading(true);
          setTimeout(() => fetchOrders(), 1500);
        }
        else {
          setValidatorMessage(null);
          toast.warn(res.msg, toastConfig);
          //setMsg({ ...msg, success: "", invalid: res.msg });
        }
      }
      );
    } catch (e) {
      //setMsg({ ...msg, invalid: "", success: "" });
      setValidatorMessage(e[0].message);
    }
  };

  const handleCredsSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const rules = {
      height: 'required|number',
      width: 'required|number',
      length: 'required|number',
      weight: 'required|number',
    };
    const messages = {
      required: (field) => `${(field.charAt(0).toUpperCase() + field.slice(1)).replace(/([a-z])([A-Z-_])/g, '$1 $2')} is required`,
    };
    try {
      const resp = await validate(labelDimensions, rules, messages);
      let payload = {
        height: labelDimensions.height,
        width: labelDimensions.width,
        length: labelDimensions.length,
        weight: labelDimensions.weight,
      }
      orderService.generateLabel(props.match.params.orderId, payload).then((res) => {
        if (res.success === true && res.label.status !== "ERROR") {
          window.open(res.label.label_url, "_blank");
        }
        else {
          setValidatorMessage(null);
          toast.warn('Unable to generate shipping label.', toastConfig);
          //setMsg({ ...msg, success: "", invalid: "Unable to generate label" });
        }
      })
    }
    catch (e) {
      //setMsg({ ...msg, invalid: "", success: "" });
      setValidatorMessage(e[0].message);
    } finally {
      setLoading(false);
    }
  }

  function fetchOrders() {
    setLoading(true);
    orderService.getOrder(props.match.params.orderId).then((res) => {
      setLoading(false);
      if (res.success === true) {
        setData(res.order);
        setShippingCreds(res.order.user);
        setStatus(res.order.status);
        setorderCreds({ ...orderCreds, amount: res.order.amount, status: res.order.status, description: res.order.description });
      }
    }).catch((error) => {
       toast.warn('Error fetching orders.', toastConfig);
    });
  }

  function fetchAudits() {
    orderService.getAudit(props.match.params.orderId).then((res) => {
      let audit = [];
      const isKeyExist = (audit, key) => audit.find(x => Object.keys(x)[0] === key) ? true : false;
      const appendData = (audit, key, prop) => (audit.find(x => Object.keys(x)[0] === key) || {})[key].push(prop);
      let name = '';
      res.audit.map((prop) => {
        if (prop.updatedByName) {
          name = prop.updatedByName;
        }
        else {
          name = prop.user.name;
        }
        if (isKeyExist(audit, name)) {
          appendData(audit, name, prop);
        }
        else {
          let updates = [];
          updates.push(prop);
          let item = { [name]: updates };
          audit.push(item);
        }
      })
      setAuditData(audit);
      setMsg({ ...msg, success: "", invalid: res.msg });
      setauditLoading(false);
    })
  }

  useEffect(() => {
    setLoading(true);
    fetchOrders();
    setauditLoading(true);
    fetchAudits();
  }, []);

  async function formSubmit(target) {
    target.preventDefault();
    let text = "";
    let formData = {};
    if (comment !== '') {
      formData.comment = comment;
      text = "Comment has been added."
    }
    if (data.status !== status) {
      formData.status = status;
      text = "Status has been updated."
    }
    if (Object.keys(formData).length !== 0) {
      await orderService.updateOrder(
        props.match.params.orderId,
        formData).then(
          (res) => {
            if (res.success === true) {
              setDisableButton(true);
              setauditLoading(true);
              setTimeout(() => fetchAudits(), 1000);
              if (formData.hasOwnProperty('status')) {
                setLoading(true);
                setTimeout(() => fetchOrders(), 1000);
              }
              setMsg({ ...msg, success: text, invalid: "" });
              if (formData.hasOwnProperty('comment')) {
                setComment('');
              }

            }
          })
    }
  }

  return (
    <div className="main-content">
      <Grid fluid className="ds-scan-details">
        <Row>
          <Col md={12}>
            <Card
              title="Overview"
              content={
                <>
                  <Row className="ds-patient-info">
                    <Col md={4} className="patient-info__userinfo">
                      <Row>
                        <Col md={4}>
                          <div className="userinfo__icon">
                            <i className="fa fa-user" />
                          </div>
                        </Col>
                        <Col md={8}>
                          <div className="scan-details__heading">
                            <h5>
                              <strong>{data.user && data.user.name}</strong>
                            </h5>
                          </div>
                          <div className="scan-details__content">
                            <span>{data.user && data.user.email}</span>
                          </div>
                          <div className="scan-details__content">
                            <span>{data.user && data.user.phoneNumber}</span>
                          </div>
                          <div className="ds-chips">
                            <div className="chips__content warning">{data.status ? data.status : "-"}</div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={4} className="patient-info__scan-detail">
                      <div className="scan-details__heading">
                        <h5>Order Details</h5>
                      </div>
                      {data.createdAt &&
                        <div className="scan-details__content">
                          Created At: {new Date(data.createdAt).toISOString().slice(0, 10)}
                        </div>
                      }
                      {data.expiry &&
                        <div className="scan-details__content">
                          Expiry Date: {new Date(data.expiry).toISOString().slice(0, 10)}
                        </div>
                      }
                      {data.amount &&
                        <div className="scan-details__content">
                          Amount: $ {data.amount}
                        </div>
                      }
                      {data.trackingNumber &&
                        <div className="scan-details__content">
                          Tracking Number: {data.trackingNumber}
                        </div>
                      }
                      {data.trackingURL &&
                        <div className="scan-details__content">
                          <a href={data.trackingURL} target="_blank"> Tracking Link </a>
                        </div>
                      }
                    </Col>
                    <Col md={4} className="patient-info__vendor-detail">
                      <div className="scan-details__heading">
                        <h5>Description</h5>
                      </div>
                      <div className="scan-details__content">{data.description}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pull-right" md={4}>
                      <ButtonToolbar className="pull-right">
                        <Button bsStyle="primary" wd fill onClick={() => { setMsg({ ...msg, success: "", invalid: "" }); setShowShippingCreds(true) }}>Generate Shipping Label</Button>
                        <Button bsStyle="primary"
                          onClick={handleShow}>Edit</Button>
                      </ButtonToolbar>
                    </Col>
                  </Row>
                </>
              }
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card
              title="Order History"
              content={
                <div className="scan-details__history">
                  <div className="scan-details__container">
                    {auditData.map((user) => {
                      return (
                        <div className="scan-details__content clearfix">
                          <div className="scan-history__profilepic">
                            <i className="fa fa-user"></i>
                          </div>
                          <div className="scan-history__content">
                            <div className="scan-history__heading">
                              {Object.keys(user)[0]}
                            </div>
                            <div className="scan-history__tasks">
                              <ul className="scan-history__tasks--li">
                                {Object.values(user)[0].map((x, index) => {
                                  if (x.field === 'status' || x.field === 'amount') {
                                    return (
                                      <li key={index} className="tasks__li set-status">
                                        <div>
                                          Changed Order {" "}
                                          <span className="task__status">
                                            {x.field}
                                          </span>
                                      from
                                      <span className="task__status">
                                            {x.previousValue}
                                          </span>
                                      To
                                      <span className="task__status">
                                            {x.currentValue}
                                          </span>
                                          <small className="status__date-time">
                                            {moment(x.createdAt).fromNow()}
                                          </small>
                                        </div>
                                      </li>
                                    )
                                  } else if ((x.field === null || x.field === undefined) && x.action === 'Create') {
                                    return (
                                      <li key={index} className="tasks__li set-status">
                                        <div>
                                          <span className="task__status">
                                            Created
                                            </span>
                                            new order
                                            <small className="status__date-time">
                                            {moment(x.createdAt).fromNow()}
                                          </small>
                                        </div>
                                      </li>
                                    )
                                  }
                                  else if (x.field === 'description') {
                                    return (
                                      <li key={index} className="tasks__li set-status">
                                        <div>
                                          Changed Order {" "}
                                          <span className="task__status">
                                            {x.field}
                                          </span>
                                      from
                                      <span className="task__status">
                                            {x.previousValue}
                                          </span>
                                      To
                                      <span className="task__status">
                                            {x.currentValue}
                                          </span>
                                          <small className="status__date-time">
                                            {moment(x.createdAt).fromNow()}
                                          </small>
                                        </div>
                                      </li>
                                    )
                                  }
                                  else if (x.comment !== undefined) {
                                    return (
                                      <li key={index} className="tasks__li task__comments">
                                        <div>
                                          <p className="task__users-comment">
                                            {x.comment}
                                            <Button className="dlt-commnet-btn" onClick={(e) => handleCommentDelete(e, x.id)} bsSize="xsmall"><Glyphicon glyph="trash" /></Button>
                                          </p>
                                          <small className="status__date-time">
                                             {moment(x.createdAt).fromNow()}
                                          </small>
                                        </div>
                                      </li>
                                    )
                                  }
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                    <div className="scan-details__comment">
                      <Form>
                        <FormGroup controlId="formControlsTextarea">
                          <FormControl
                            componentClass="textarea"
                            placeholder="Comment"
                            value={comment}
                            onChange={(event) => {
                              if (event.value !== '') { setDisableButton(false); }
                              setComment(event.target.value)
                            }} />
                        </FormGroup>
                        <FormGroup className="action-btns clearfix">
                          <Button bsStyle="primary" wd fill disabled={disableButton} onClick={(e) => formSubmit(e)}>
                            {auditloading && <ReactLoading className="btn-loading" type="spin" color="#ffffff" height={20} width={20} />}
                            Update
                          </Button>                          <div className="pull-right">
                            <div className="comment__actions">
                              <small>Status: </small>
                              <Select
                                menuPlacement="top"
                                maxMenuHeight={250}
                                isSearchable="true"
                                placeholder="Set status "
                                name="status"
                                value={orderStatuses.filter(option => option.value === status)}
                                className="actions_dropdown"
                                options={orderStatuses}
                                onChange={(status) => {
                                  setDisableButton(false);
                                  setStatus(status.value);
                                  setorderCreds({ ...orderCreds, status: status.value })
                                }}
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </Form>
                      <div>
                        {msg.success && (
                          <div
                            className="alert alert-success mt-2"
                            style={{
                              display: msg.success ? 'block' : 'none',
                            }}
                            role="alert"
                          >
                            {' '}
                            {msg.success}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              }
            />
          </Col>
        </Row>
      </Grid>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <Form horizontal>
                <div>
                  {validatorMessage && (
                    <div
                      className="alert alert-danger mt-2"
                      style={{
                        display: validatorMessage ? 'block' : 'none',
                      }}
                      role="alert"
                    >
                      {' '}
                      {validatorMessage}
                    </div>
                  )}
                </div>
                <div>
                  {msg.invalid && (
                    <div
                      className="alert alert-danger mt-2"
                      style={{
                        display: msg.invalid ? 'block' : 'none',
                      }}
                      role="alert"
                    >
                      {' '}
                      {msg.invalid}
                    </div>
                  )}
                </div>
                <div>
                  {msg.success && (
                    <div
                      className="alert alert-success mt-2"
                      style={{
                        display: msg.success ? 'block' : 'none',
                      }}
                      role="alert"
                    >
                      {' '}
                      {msg.success}
                    </div>
                  )}
                </div>
                <FormGroup controlId="formHorizontaldescription">
                  <Col componentClass={ControlLabel} sm={3}>
                    Description
              </Col>
                  <Col sm={9}>
                    <FormControl type="text" name="description" value={orderCreds.description} onChange={handleChange} placeholder="Description" />
                  </Col>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" fill disabled={disableButton} onClick={(e) => { handleSubmit(e) }}>
            Update
            </Button>
          <Button bsStyle="default" onClick={handleClose}>
            Close
            </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showShippingCreds} onHide={() => setShowShippingCreds(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Shipping Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {validatorMessage && (
              <div
                className="alert alert-danger mt-2"
                style={{
                  display: validatorMessage ? 'block' : 'none',
                }}
                role="alert"
              >
                {' '}
                {validatorMessage}
              </div>
            )}
          </div>
          <div>
            {msg.invalid && (
              <div
                className="alert alert-danger mt-2"
                style={{
                  display: msg.invalid ? 'block' : 'none',
                }}
                role="alert"
              >
                {' '}
                {msg.invalid}
              </div>
            )}
          </div>
          <div>
            {msg.success && (
              <div
                className="alert alert-success mt-2"
                style={{
                  display: msg.success ? 'block' : 'none',
                }}
                role="alert"
              >
                {' '}
                {msg.success}
              </div>
            )}
          </div>
          <Row>
            <Col componentClass={ControlLabel} sm={3}>
              Name:
            </Col>
            <Col md={9}>
              <span>{shippingCreds.name}</span>
            </Col>
          </Row>
          <Row>
            <Col componentClass={ControlLabel} sm={3}>
              Email:
            </Col>
            <Col md={9}>
              <span>{shippingCreds.email}</span>
            </Col>
          </Row>
          <Row>
            <Col componentClass={ControlLabel} sm={3}>
              Phone Number:
            </Col>
            <Col md={9}>
              <span>{shippingCreds.phoneNumber}</span>
            </Col>
          </Row>
          <Row>
            <Col componentClass={ControlLabel} sm={3}>
              Shipping Address:
            </Col>
            <Col md={9}>
              <span>{shippingCreds.address && shippingCreds.address.address1}</span>
            </Col>
          </Row>
          <Row>
            <Col componentClass={ControlLabel} sm={3}>
              City:
            </Col>
            <Col md={9}>
              <span>{shippingCreds.address && shippingCreds.address.city}</span>
            </Col>
          </Row>
          <Row>
            <Col componentClass={ControlLabel} sm={3}>
              Zipcode:
            </Col>
            <Col md={9}>
              <span>{shippingCreds.address && shippingCreds.address.zipcode}</span>
            </Col>
          </Row>
          <Row>
            <Col componentClass={ControlLabel} sm={3}>
              State:
            </Col>
            <Col md={9}>
              <span>{shippingCreds.address && shippingCreds.address.state}</span>
            </Col>
          </Row>
          <Row>
            <Col componentClass={ControlLabel} sm={3}>
              Country:
            </Col>
            <Col md={9}>
              <span>{shippingCreds.address && shippingCreds.address.country}</span>
            </Col>
          </Row>

          <Form horizontal>
            <FormGroup>
              <Col componentClass={ControlLabel} sm={3}>
                Package Height:(in)
              </Col>
              <Col sm={4}>
                <FormControl type="number" name="height" value={labelDimensions.height} onChange={handleLabelDimensionChange} placeholder="Height" />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col componentClass={ControlLabel} sm={3}>
                Package Width:(in)
              </Col>
              <Col sm={4}>
                <FormControl type="number" name="width" value={labelDimensions.width} onChange={handleLabelDimensionChange} placeholder="Width" />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col componentClass={ControlLabel} sm={3}>
                Package Length:(in)
              </Col>
              <Col sm={4}>
                <FormControl type="number" name="length" value={labelDimensions.length} onChange={handleLabelDimensionChange} placeholder="Length" />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col componentClass={ControlLabel} sm={3}>
                Package Weight:(lb)
              </Col>
              <Col sm={4}>
                <FormControl type="number" name="weight" value={labelDimensions.weight} onChange={handleLabelDimensionChange} placeholder="Weight" />
              </Col>
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar>
            <Button bsStyle="primary" pullRight onClick={() => { setShowShippingCreds(false); setMsg({ ...msg, success: "", invalid: "" }); }}> Close
          </Button>
            {!loading ? <Button type="submit" bsStyle="primary" pullRight fill onClick={handleCredsSubmit}>
              Generate</Button> :
              <Button bsStyle="primary" pullRight fill disabled>
                Generating...
           </Button>}

          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    </div>
  );

}
export default Order;
