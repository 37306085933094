import React, { useEffect } from "react";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
// react component used to create charts
import ChartistGraph from "react-chartist";
import vendorService from 'services/vendorService';
import { useHistory } from 'react-router';
import Card from "components/Card/Card.jsx";
import StatsCard from "components/Card/StatsCard.jsx";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";


function Vendor(props) {
  const [orderCount, setOrderCount] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [pageload, setPageload] = React.useState(true);
  const [vendor, setVendor] = React.useState();
  const [deviceCount, setDeviceCount] = React.useState(0);
  const [userCount, setUserCount] = React.useState(0);
  const [sessionCount, setSessionCount] = React.useState(0);
  const [orderData, setOrderData] = React.useState({ labels: [], series: [] });
  const [sessionData, setSessionData] = React.useState({ labels: [], series: [] });
  const history = useHistory();

  async function fetchVendor() {
    setLoading(true);
    await vendorService.vendor(props.match.params.vendorId).then((res) => {
      console.log(res);
      setVendor(res.vendor);
      setOrderCount(res.orderCount);
      setOrderData({
        ...orderData, labels: Object.values(res.orderCount).map((item) => item.status + " : " + item.count),
        series: Object.values(res.orderCount).map((item) => item.count)
      });
      setSessionData({
        ...sessionData, labels: Object.values(res.sessionCount).map((item) => item.status + " : " + item.count),
        series: Object.values(res.sessionCount).map((item) => item.count)
      });
      setDeviceCount(res.deviceCount);
      setUserCount(res.userCount);
      setSessionCount(Object.values(res.sessionCount).reduce(function (sum, item) {
        if (item.status !== "Complete") { return sum + +item.count }
        return sum;
      }, 0)
      );
      setLoading(false);
      setPageload(false);
    });
  }

  useEffect(() => {
    setPageload(true);
    fetchVendor();

  }, []);

  let options = {
    donut: true,
    donutWidth: 60,
    donutSolid: true,
    startAngle: 90,
    showLabel: true,
  }
  let plugins = {
    plugins: [

    ]
  }
  if (pageload) {
    return (
      <Loader className="text-center" visible={loading} type="ThreeDots" color="#4cb276" height={15} width={80} />
    );
  }
  else {
    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col lg={4} sm={6}>
              <StatsCard
                bigIcon={<i className="fa fa-users text-warning" />}
                statsText={
                  <a className="statscard-link">Users</a>
                }
                statsValue={userCount ? userCount : "0"}
                // statsIcon={<i className="fa fa-users" />}
                statsIconText={
                  <a className="statscard-link" onClick={() => { history.push("/admin/users") }}>View All</a>
                }
              />
            </Col>
            <Col lg={4} sm={6}>
              <StatsCard
                bigIcon={<i className="fa fa-server text-success" />}
                statsText={
                  <a className="statscard-link">Accesories</a>
                }
                statsValue={deviceCount}
                statsIconText={
                  <a className="statscard-link" onClick={() => { history.push("/admin/accessories") }}>View All</a>
                }
              />
            </Col>
            <Col lg={4} sm={6}>
              <StatsCard
                bigIcon={<i className="fa fa-archive text-danger" />}
                statsText={
                  <a className="statscard-link">Active Sessions</a>
                }
                statsValue={sessionCount ? sessionCount : "0"}
                statsIconText={
                  <a className="statscard-link" onClick={() => { history.push("/admin/active-sessions") }}>View All</a>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Card
                title={
                  <a className="card-link">Sessions</a>
                }
                category="Sessions summary"
                content={loading ? <Loader className="text-center" visible={loading} type="ThreeDots" color="#4cb276" height={15} width={80} /> :
                  <ChartistGraph data={sessionData} type="Pie" />}
              />
            </Col>
            <Col md={8}>
              <Card
                title={
                  <a className="card-link">Orders</a>
                }
                category="Orders summary"
                content={loading ?
                  <Loader className="text-center" visible={loading} type="ThreeDots" color="#4cb276" height={15} width={80} /> :
                  <ChartistGraph
                    data={orderData}
                    type="Pie"
                    options={options}
                    plugins={plugins}
                  />
                }
              />
            </Col>
          </Row>
        </Grid>
      </div >
    );
  }

}

export default Vendor;
