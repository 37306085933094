import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import FormGroup from "react-bootstrap/lib/FormGroup";
import ControlLabel from "react-bootstrap/lib/ControlLabel";
import FormControl from "react-bootstrap/lib/FormControl";

// reactstrap components
import React, { useEffect } from "react";
import authService from "services/authService.jsx";
import { validate } from "indicative/validator";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";

// core components
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import logo from "assets/brand/icon-white.svg";

function ResetPassword(props) {
  const [pageState, setPageState] = React.useState({
    invalidToken: false,
    password: "",
    passwordConfirmation: "",
    errorMsg: "",
    isLoading: true,
    successMsg: "",
    buttonDisabled: false,
  });
  const [cardState, setCardState] = React.useState(true);

  const history = useHistory();

  const handleChange = (e) => {
    setPageState({
      ...pageState,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    let mounted = true;
    setTimeout(function () {
      if (mounted) setCardState(false);
    }, 700);
    authService.verifyResetToken(props.match.params.code).then((result) => {
      if (result.success)
        setPageState({ ...pageState, invalidToken: false, isLoading: false });
      else
        setPageState({
          ...pageState,
          successMsg: "",
          invalidToken: true,
          errorMsg: result.msg,
        });
    });
    return () => (mounted = false);
  }, []);

  const handleSend = async (e) => {
    e.preventDefault();
    setPageState({ ...pageState, buttonDisabled: true });
    const rules = {
      password: "required|min:6|confirmed",
    };
    const messages = {
      required: (field) =>
        `${field.charAt(0).toUpperCase() + field.slice(1)} is required`,
      "password.min": "Password is too short",
      "password.confirmed": "Passwords do not match",
    };
    const payload = {
      password: pageState.password,
      password_confirmation: pageState.passwordConfirmation,
    };
    try {
      await validate(payload, rules, messages);

      const res = await authService.resetPassword(
        props.match.params.code,
        pageState.password,
        pageState.passwordConfirmation
      );
      if (res.success) {
        setPageState({
          ...pageState,
          successMsg:
            "Your password has been successfully updated. Redirecting to login...",
          errorMsg: "",
          buttonDisabled: true,
        });
        setTimeout(() => history.push("/auth/login"), 3000);
      } else
        setPageState({
          ...pageState,
          successMsg: "",
          errorMsg: res.msg,
          buttonDisabled: false,
        });
    } catch (e) {
      setPageState({
        ...pageState,
        successMsg: "",
        errorMsg: e[0].message,
        buttonDisabled: false,
      });
    }
  };

  if (pageState.invalidToken) {
    return (
      <Grid>
        <Row>
          <Col md={4} sm={6} mdOffset={4} smOffset={3}>
            <form>
              <Card
                hidden={cardState}
                content={
                  <div>
                    <div className="login-icon">
                      <img src={logo} alt="react-logo" />
                    </div>
                    <FormGroup>
                      <h4>
                        Your link has expired or is invalid. Please request
                        another reset link.
                      </h4>
                    </FormGroup>
                  </div>
                }
                ftTextCenter
              />
              <div>
                <Link to="/auth/login">Back to Login?</Link>
              </div>
            </form>
          </Col>
        </Row>
      </Grid>
    );
  } else if (pageState.isLoading) {
    return (
      <Grid>
        <Row>
          <Col md={4} sm={6} mdOffset={4} smOffset={3}>
            <form>
              <Card
                hidden={cardState}
                content={
                  <div>
                    <div className="login-icon">
                      <img src={logo} alt="react-logo" />
                    </div>
                    <FormGroup>
                      <h4>Loading User Data...</h4>
                    </FormGroup>
                  </div>
                }
                ftTextCenter
              />
              <div>
                <Link to="/auth/login">Back to Login?</Link>
              </div>
            </form>
          </Col>
        </Row>
      </Grid>
    );
  } else {
    return (
      <Grid>
        <Row>
          <Col md={4} sm={6} mdOffset={4} smOffset={3}>
            <form onSubmit={handleSend}>
              <Card
                hidden={cardState}
                content={
                  <div>
                    <div className="login-icon">
                      <img src={logo} alt="react-logo" />
                    </div>
                    <FormGroup>
                      {pageState.errorMsg && (
                        <div
                          className="alert alert-danger mt-2"
                          style={{
                            display: pageState.errorMsg ? "block" : "none",
                          }}
                          role="alert"
                        >
                          {" "}
                          {pageState.errorMsg}
                        </div>
                      )}
                      {pageState.successMsg && (
                        <div
                          className="alert alert-success mt-2"
                          style={{
                            display: pageState.successMsg ? "block" : "none",
                          }}
                          role="alert"
                        >
                          {" "}
                          {pageState.successMsg}{" "}
                        </div>
                      )}
                      <ControlLabel>Password</ControlLabel>
                      <FormControl
                        placeholder="Password"
                        type="password"
                        autoComplete="off"
                        name="password"
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>Password</ControlLabel>
                      <FormControl
                        placeholder="Confirm Password"
                        type="password"
                        autoComplete="off"
                        name="passwordConfirmation"
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </div>
                }
                legend={
                  <Button
                    bsStyle="primary"
                    disabled={pageState.buttonDisabled}
                    fill
                    wd
                    type="submit"
                  >
                    Reset Password
                  </Button>
                }
                ftTextCenter
              />
              <div>
                <Link to="/auth/login">Back to Login?</Link>
              </div>
            </form>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default ResetPassword;
