import React from 'react';
import ColumnFilter from './ColumnFilter';
import FilterInput from './FilterInput';
import ButtonToolbar from 'react-bootstrap/lib/ButtonToolbar';
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

function Table({
  columns,
  data,
  fetchData,
  loading,
  pageCount: controlledPageCount,
  totalCount,
  selectData,
}) {

  const [controlledSelectState, setControlledSelectState] = React.useState({ selectData });

  const defaultColumn = React.useMemo(
    () => ({
      Filter: ColumnFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setFilter,
    // Get the state from the instance
    state: {
      pageIndex,
      pageSize,
      sortBy,
      filters,
    },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      manualFilters: true,
      //filterTypes,
      initialState: {
        pageIndex: 0,
      }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount,
      useControlledState: state => {
        return React.useMemo(
          () => ({
            ...state,
            selectData
          }),
          [state, selectData]
        )
      },
    },
    useFilters,
    useSortBy,
    usePagination
  )

  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
    fetchData({ pageIndex, pageSize, sortBy, filters })
  }, [sortBy, fetchData, pageIndex, pageSize, filters, controlledSelectState])

  // Render the UI for your table
  return (
    <>
      <div className="ds-data-table">
        <table className="data-table" {...getTableProps()}>
          <thead className="ds-thead">
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th className="ds-th" {...column.getHeaderProps()}>
                    <div {...column.getSortByToggleProps()}>
                      <span>{column.render("Header")}</span>
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? <i className="fa fa-sort-down"></i>
                            : <i className="fa fa-sort-up"></i>
                          : ""}
                      </span>
                    </div>
                    <FilterInput column={column} selectData={selectData} />
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="ds-tbody" {...getTableBodyProps()}>
            {loading &&
              <tr>
                <td colSpan="100%" className="ds-td">
                  <div className="ds-loading-spinner data-table-loading">
                    <Loader className="loading-spinner__svg" type="ThreeDots" color="#4cb276" height={30} />
                  </div>
                </td>
              </tr>
            }
            {!loading && page.map((row, i) => {
              prepareRow(row);
              return (
                <tr className="ds-tr" {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td className="ds-td" {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {/*
          Pagination can be built however you'd like.
          This is just a very basic UI implementation:
        */}
        <div className="table-bottom">
          <div className="pagination">
            <div className="table-rows-info">
              {loading ? (
                // Use our custom loading state to show a loading indicator
                <span>Loading...</span>
              ) : (
                  <span>Showing <strong>{page.length}</strong> of {totalCount} results</span>
                )}
            </div>
            <button className="btns  --first" onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}></button>
            <button className="btns --previous" onClick={() => previousPage()}
              disabled={!canPreviousPage}></button>
            <span className="pageinfo">
              Page
                <strong>{pageOptions.length === 0 ? 0 : pageIndex + 1} of {pageOptions.length}</strong>
            </span>
            <button className="btns  --next" onClick={() => nextPage()}
              disabled={!canNextPage}></button>
            <button className="btns --last" onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}></button>

            <span className="jump-to-page">
              Go to page:
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  gotoPage(page)
                }}
                style={{ width: '100px' }}
              />
            </span>{' '}
            <select className="select-page"
              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </>
  )
}

export default Table;
