import { instance as axios } from "./http-common";

export default {
  getSessions,
  getSession,
  getAudit,
  getScanURL,
  updateSession,
};

async function getSessions(active, size, page, sortBy, filters) {
  if (sortBy && sortBy.length > 0) {
    var order = sortBy[0].desc ? 'desc' : 'asc';
    sortBy = sortBy[0].id;
  }

  let params = {
    size,
    page,
    sortBy,
    order,
    active: active,
  }

  let res;
  let config = {};
  if (filters) {
    filters.forEach(function (v) {
      params[v.id] = v.value;
    })
  }
  try {
    res = await axios.get(
      `sessions`,
      { params },
      config
    );
    if (res.status === 200 && res.data.success === true) {
      return {
        success: true,
        sessions: res.data.sessions,
        count: res.data.count,
      };
    }
    else return { success: false, msg: res.error.message };
  } catch (e) {
    console.log(e.response);
    return { success: false, msg: "Unable to fetch sessions." };
  }
}

async function getSession(sessionId, size, page, sortBy, filters) {
  if (sortBy && sortBy.length > 0) {
    var order = sortBy[0].desc ? 'desc' : 'asc';
    sortBy = sortBy[0].id;
  }
  let res;
  let config = {};
  let params = {
    size,
    page,
    sortBy,
    order,
  }
  if (filters) {
    filters.forEach(function (v) {
      params[v.id] = v.value;
    })
  }
  try {
    res = await axios.get(
      `sessions/${sessionId}`,
      { params },
      config
    );
    if (res.status === 200 && res.data.success === true)
      return {
        success: true,
        session: res.data.session,
        count: res.data.count,
      };
    else return { success: false, msg: res.error.message };
  } catch (e) {
    console.log(e.response);
    return { success: false, msg: "Unable to fetch session." };
  }
}

async function getScanURL(sessionId, scanId) {
  let res;
  let config = {};
  try {
    res = await axios.get(
      `sessions/${sessionId}/scans/${scanId}`,
      config
    );
    if (res.status === 200 && res.data.success === true)
      return { success: true, urls: res.data.scanStatus.modelUrls };
    else return { success: false, msg: res.error.message };
  } catch (e) {
    console.log(e.response);
    return { success: false, msg: "Unable to fetch scan URL." };
  }
}

async function getAudit(sessionId) {
  let res;
  let config = {};
  try {
    res = await axios.get(
      `sessions/${sessionId}/audit`,
      config
    );
    if (res.status === 200 && res.data.success === true)
      return { success: true, audit: res.data.audit };
    else return { success: false, msg: res.error.message };
  } catch (e) {
    console.log(e.response);
    return { success: false, msg: "Unable to fetch audit data." };
  }
}

async function updateSession(sessionId, data) {
  let res;
  let config = {};
  try {
    res = await axios.post(
      `sessions/${sessionId}/`,
      data,
      config
    );
    if (res.status === 200 && res.data.success === true)
      return { success: true, sessions: res.data.sessions };
    else return { success: false, msg: res.error.message };
  } catch (e) {
    console.log(e.response);
    return { success: false, msg: "Unable to update session." };
  }
}
