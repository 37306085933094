import { instance as axios } from "./http-common";

export default {
  getOrders,
  getOrder,
  getAudit,
  addOrder,
  updateOrder,
  getUserOrderStats,
  generateLabel,
};

async function getOrders(size, page, sortBy, filters) {
  if (sortBy && sortBy.length > 0) {
    var order = sortBy[0].desc ? 'desc' : 'asc';
    sortBy = sortBy[0].id;
  }

  let params = {
    size,
    page,
    sortBy,
    order,
  }
  let res;
  if (filters) {
    filters.forEach(function (v) {
      params[v.id] = v.value;
    })
  }
  let config = {
    params
  };
  try {
    res = await axios.get(
      `orders`,
      config
    );
    if (res.status === 200 && res.data.success === true)
      return { success: true, orders: res.data.orders, count: res.data.count };
    else return { success: false, msg: res.error.message };
  } catch (e) {
    return { success: false, msg: "Unable to fetch orders." };
  }
}

async function getAudit(orderId) {
  let res;
  let config = {};
  try {
    res = await axios.get(
      `orders/${orderId}/audit`,
      config
    );
    if (res.status === 200 && res.data.success === true)
      return { success: true, audit: res.data.audit };
    else return { success: false, msg: res.error.message };
  } catch (e) {
    console.log(e.response);
    return { success: false, msg: "Unable to fetch audit data." };
  }
}

async function getUserOrderStats(userId) {
  let res;
  let config = {};
  try {
    res = await axios.get(
      `orders/orderstats/${userId}`,
      config
    );
    if (res.status === 200 && res.data.success === true)
      return { success: true, response: res.data.response };
    else return { success: false, msg: res.data.error.message };
  } catch (e) {
    return { success: false, msg: "Unable to fetch orders." };
  }
}

async function getOrder(orderId) {
  let res;
  // commented out due to merge conflicts
  /*if (filters) {
    filters.forEach(function (v) {
      params[v.id] = v.value;
    })
  }*/
  let config = {};

  try {
    res = await axios.get(
      `orders/${orderId}`,
      config
    );
    if (res.status === 200 && res.data.success === true) {
      return { success: true, order: res.data.order };
    }
    else return { success: false, msg: res.error.message };
  } catch (e) {
    return { success: false, msg: "Unable to fetch order." };
  }
}

async function addOrder(order) {
  let res;
  let config = {};
  try {
    res = await axios.post(
      `orders`, order, config
    );
    if (res.status === 200 && res.data.success === true)
      return { success: true };
    else return { success: false, msg: res.data.error.message };
  } catch (e) {
    if (e.response.data.error.message) {
      return { success: false, msg: e.response.data.error.message };
    }
    return { success: false, msg: "Unable to add order" };
  }
}
async function updateOrder(orderId, order) {
  let res;
  let config = {};
  try {
    res = await axios.post(
      `orders/${orderId}`, order, config
    );
    if (res.status === 200 && res.data.success === true)
      return { success: true };
    else {
      return { success: false, msg: res.error.message }
    };
  } catch (e) {
    return { success: false, msg: "Unable to update order." };
  }
}

async function generateLabel(orderId, payload) {
  let res;
  let config = {};
  try {
    res = await axios.post(
      `orders/${orderId}/label`, payload,
      config
    );
    if (res.status === 200 && res.data.success === true) {
      return { success: true, label: res.data.label };
    }

    else return { success: false, msg: "Unable to generate label." };
  } catch (e) {
    console.log(e.response);
    return { success: false, msg: "Unable to generate label." };
  }
}
