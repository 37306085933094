import rules from "../rbac-rules";
import { role } from "../views/Pages/LoginPage.jsx";
const check = (role, action) => {
  const permissions = rules[role];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }
  if (permissions.static && permissions.static.includes(action)) {
    // static permission is granted to this role
    return true;
  }
};
export const can_navigate = (routeName) => {
  let allowed_routes = [];
  if (role) {
    allowed_routes = rules[role].pages;
    return allowed_routes.includes(routeName);
  }
  else if (localStorage.getItem("role") !== undefined) {
    const _role = localStorage.getItem("role");
    if (rules[_role]) {
      allowed_routes = rules[_role].pages;
      return allowed_routes.includes(routeName);
    }
    return false;
  }
}
const Can = (action) => {
  let can = false;
  if (role) {
    can = check(role, action);
  }
  else if (localStorage.getItem("role") !== undefined) {
    const _role = localStorage.getItem("role");
    can = check(_role, action);
  }
  return can;
}

export const getRole = () => {
  return localStorage.getItem("role");
}

export default Can;
