import React, { useEffect, useState } from "react";
// react component for creating dynamic tables
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import Modal from "react-bootstrap/lib/Modal";
import Form from "react-bootstrap/lib/Form";
import FormGroup from "react-bootstrap/lib/FormGroup";
import ControlLabel from "react-bootstrap/lib/ControlLabel";
import FormControl from "react-bootstrap/lib/FormControl";
import ButtonToolbar from "react-bootstrap/lib/ButtonToolbar";

import { useHistory } from 'react-router';
import { validate } from 'indicative/validator';
import Table from "components/Table/Table.jsx";
import ColumnFilter from "components/Table/ColumnFilter.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import vendorService from 'services/vendorService';
import debounce from 'components/Debounce';
import Can from "../components/Can.jsx";
import { US_states } from "../variables/Variables.jsx";
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import Select from 'react-select';

function Vendors(props) {
  const history = useHistory();
  const [showVendorCreate, setShowVendorCreate] = useState(false);
  const [msg, setMsg] = React.useState({ "success": "", "invalid": "" });
  const [validatorMessage, setValidatorMessage] = React.useState(null);
  const [vendorCreds, setvendorCreds] = React.useState({
    id: "",
    name: "",
    email: "",
    //paypalEmail: "",
    //paypalMerchantID: "",
    phoneNumber: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
  });
  const [data, setData] = React.useState([]);
  const [pageCount, setPageCount] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  function fetchVendors({ pageSize, pageIndex, sortBy, filters }) {
    vendorService.vendors(pageSize, pageIndex, sortBy, filters).then((res) => {
      if (res.success) {
        let dataTable = res.vendors;
        setData(
          dataTable.map((prop) => {
            return {
              id: prop.id,
              name: prop.name,
              email: prop.email,
              phoneNumber: prop.phoneNumber,
              actions: (
                <div className="actions-right">
                  <Button
                    bsStyle="primary"
                    simple
                    icon
                    onClick={(e) => {
                      let obj = dataTable.find((o) => o.id === prop.id);
                      history.push(`/admin/vendor/${obj.id}`);

                    }}
                  >
                    <i className="fa fa-eye" />
                  </Button>{" "}
                  {Can("vendors-page:edit") &&
                    <Button
                      onClick={(e) => {
                        let obj = dataTable.find((o) => o.id === prop.id);
                        history.push(`/admin/vendor/${obj.id}/profile`);
                      }}
                      bsStyle="warning"
                      simple
                      icon
                    >
                      <i className="fa fa-edit" />
                    </Button>}
                </div>
              ),
            };
          }),
        );
        setPageCount(Math.ceil(res.count / pageSize));
        setTotalCount(res.count);
      }
    });
  }

  const fetchData = React.useCallback(debounce(fetchVendors, 500), []);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Filter: ColumnFilter,
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Contact Number",
        accessor: "phoneNumber",
        disableSortBy: true,
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableSortBy: true,
        disableFilters: true,
      },
    ],
    []
  )
  useEffect(() => {
    fetchVendors({});
  }, []);

  const handleAddVendor = (e) => {
    setMsg({ ...msg, success: "", invalid: "" });
    setValidatorMessage(null);
    setShowVendorCreate(true);
  }
  const handlePhoneChange = e => {
    setvendorCreds({ ...vendorCreds, phoneNumber: e });
  }
  const handleChange = (e) => {
    setMsg({ ...msg, success: "", invalid: "" });
    setValidatorMessage(null);
    setvendorCreds({
      ...vendorCreds,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const rules = {
      name: 'required',
      email: 'required',
      //paypalEmail: 'required',
      //paypalMerchantID: 'required',
      phone_number: 'required',
      address1: 'required',
      zipcode: 'required',
      city: 'required',
      state: 'required',
    };
    const error = [{ message: "Please enter a valid phone number" }];
    const messages = {
      required: (field) => `${(field.charAt(0).toUpperCase() + field.slice(1)).replace(/([a-z])([A-Z-_])/g, '$1 $2')} is required`,
    };
    const payload = {
      name: vendorCreds.name,
      email: vendorCreds.email,
      //paypalEmail: vendorCreds.paypalEmail,
      //paypalMerchantID: vendorCreds.paypalMerchantID,
      phone_number: vendorCreds.phoneNumber,
      address1: vendorCreds.address1,
      address2: vendorCreds.address2,
      zipcode: vendorCreds.zipcode,
      state: vendorCreds.state,
      city: vendorCreds.city,
    }
    try {
      const resp = await validate(payload, rules, messages);
      const isValidNumber = isPossiblePhoneNumber(vendorCreds.phoneNumber);
      if (!isValidNumber) {
        throw error;
      }

      vendorService.addVendor(payload).then((res) => {
        if (res.success === true) {
          setValidatorMessage(null);
          setMsg({ ...msg, success: "Vendor Added Successfully!", invalid: "" });
          setTimeout(() => setShowVendorCreate(false), 3000);
        }
        else {
          setValidatorMessage(null);
          setMsg({ ...msg, success: "", invalid: res.msg });
        }
      });
    } catch (e) {
      console.log(e);
      setMsg({ ...msg, invalid: "", success: "" });
      setValidatorMessage(e[0].message);
    }
  };
  return (
    <div className="main-content">
      <Grid fluid>
        <Row className="ds-table-row">
          <Col md={12}>
            <Table
              columns={columns}
              data={data}
              fetchData={fetchData}
              loading={loading}
              pageCount={pageCount}
              totalCount={totalCount}
            />
          </Col>
        </Row>
        {Can("vendors-page:create") && <ButtonToolbar className="pull-right">
          <Button bsStyle="primary" fill onClick={handleAddVendor}>
            Add Vendor
          </Button>
        </ButtonToolbar>}
      </Grid>
      <Modal show={showVendorCreate} onHide={() => setShowVendorCreate(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Vendor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form horizontal>
            <div>
              {validatorMessage && (
                <div
                  className="alert alert-danger mt-2"
                  style={{
                    display: validatorMessage ? 'block' : 'none',
                  }}
                  role="alert"
                >
                  {' '}
                  {validatorMessage}
                </div>
              )}
            </div>
            <div>
              {msg.invalid && (
                <div
                  className="alert alert-danger mt-2"
                  style={{
                    display: msg.invalid ? 'block' : 'none',
                  }}
                  role="alert"
                >
                  {' '}
                  {msg.invalid}
                </div>
              )}
            </div>
            <div>
              {msg.success && (
                <div
                  className="alert alert-success mt-2"
                  style={{
                    display: msg.success ? 'block' : 'none',
                  }}
                  role="alert"
                >
                  {' '}
                  {msg.success}
                </div>
              )}
            </div>
            <FormGroup controlId="formHorizontalname">
              <Col componentClass={ControlLabel} sm={3}>
                Vendor Name
              </Col>
              <Col sm={9}>
                <FormControl type="text" name="name" onChange={handleChange} placeholder="Name" />
              </Col>
            </FormGroup>
            <FormGroup controlId="formHorizontalemail">
              <Col componentClass={ControlLabel} sm={3}>
                Vendor Email
              </Col>
              <Col sm={9}>
                <FormControl type="text" name="email" onChange={handleChange} placeholder="Email" />
              </Col>
            </FormGroup>
            <FormGroup controlId="formHorizontalPhone">
              <Col componentClass={ControlLabel} sm={3}>
                Phone Number
              </Col>
              <Col sm={9}>
                <FormControl componentClass={PhoneInput} defaultCountry="US" name="phoneNumber" onChange={handlePhoneChange} />
              </Col>
            </FormGroup>
            <FormGroup controlId="formHorizontalAddress1">
              <Col componentClass={ControlLabel} sm={3}>
                Address Line 1
              </Col>
              <Col sm={9}>
                <FormControl type="text" name="address1" onChange={handleChange} placeholder="Address Line 1" />
              </Col>
            </FormGroup>
            <FormGroup controlId="formHorizontalAddress2">
              <Col componentClass={ControlLabel} sm={3}>
                Address Line 2
              </Col>
              <Col sm={9}>
                <FormControl type="text" name="address2" onChange={handleChange} placeholder="Address Line 2" />
              </Col>
            </FormGroup>
            <FormGroup controlId="formHorizontalCity">
              <Col componentClass={ControlLabel} sm={3}>
                City
              </Col>
              <Col sm={9}>
                <FormControl type="text" name="city" onChange={handleChange} placeholder="City" />
              </Col>
            </FormGroup>
            <FormGroup controlId="formHorizontalState">
              <Col componentClass={ControlLabel} sm={3}>
                State
              </Col>
              <Col sm={9}>
                <Select
                  placeholder="State"
                  name="state"
                  value={US_states.filter(option => option.value === vendorCreds.state)}
                  className="select"
                  options={US_states}
                  onChange={(e) => setvendorCreds({ ...vendorCreds, state: e.value })}
                />
              </Col>
            </FormGroup>
            <FormGroup controlId="formHorizontalZip">
              <Col componentClass={ControlLabel} sm={3}>
                Zip Code
              </Col>
              <Col sm={9}>
                <FormControl type="text" name="zipcode" onChange={handleChange} placeholder="Zip Code" />
              </Col>
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
        <Button type="submit" bsStyle="primary" fill onClick={handleSubmit}>Add Vendor</Button>
          <Button bsStyle="default" onClick={() => { setShowVendorCreate(false); setMsg({ success: "", invalid: "" }); setValidatorMessage(null); }}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Vendors;
