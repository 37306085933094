var defaultWidth =
  window.screen.width > 768
    ? (window.screen.width * 1) / 3
    : window.screen.width;

var sessionStatuses = [
  { value: 'Pending Approval', label: 'Pending Approval' },
  { value: 'Pending Assignment', label: 'Pending Assignment' },
  { value: 'Review', label: "Review" },
  { value: 'Payment Pending', label: 'Payment Pending' },
  { value: 'Ongoing', label: 'Ongoing' },
  { value: 'Complete', label: 'Complete' },
];

var orderStatuses = [
  { value: 'Review', label: 'Review' },
  { value: 'Cancelled', label: 'Cancelled' },
  { value: 'Expired', label: "Expired" },
  { value: 'Payment Pending', label: 'Payment Pending' },
  { value: 'Paid', label: 'Paid' },
  { value: 'Shipped', label: 'Shipped' },
];

var deviceStatuses = [
  { value: 'RMA', label: 'RMA' },
  { value: 'Active', label: 'Active' },
  { value: 'Inactive', label: 'Inactive' },
  { value: 'Shipped', label: 'Shipped' },
];

var scanStatuses = [
  { value: 'Printing', label: 'Printing' },
  { value: 'Complete', label: 'Complete' },
  { value: 'Review', label: 'Review' },
  { value: 'Shipping', label: 'Shipping' },
  { value: 'Retake', label: 'Retake' },
  { value: 'Modeling', label: 'Modeling' },
  { value: 'Uploaded', label: 'Scan Uploaded' },
  { value: 'Pending Modeling', label: 'Pending Modeling' },
];

var roles = [
  { value: 'Vendor Admin', label: 'Vendor Admin' },
  { value: 'Vendor Associate', label: 'Vendor Associate' },
  { value: 'Super Admin', label: 'Super Admin' },
  { value: 'User', label: 'User' },
];

var US_states = [
    { label : 'Alabama', value: 'AL'},
    { label : 'Alaska', value: 'AK'},
    { label : 'American Samoa', value: 'AS'},
    { label : 'Arizona', value: 'AZ'},
    { label : 'Arkansas', value: 'AR'},
    { label : 'California', value: 'CA'},
    { label : 'Colorado', value: 'CO'},
    { label : 'Connecticut', value: 'CT'},
    { label : 'Delaware', value: 'DE'},
    { label : 'District of columbia', value: 'DC'},
    { label : 'Federated states of micronesia', value: 'FM'},
    { label : 'Florida', value: 'FL'},
    { label : 'Georgia', value: 'GA'},
    { label : 'Guam', value: 'GU'},
    { label : 'Hawaii', value: 'HI'},
    { label : 'Idaho', value: 'ID'},
    { label : 'Illinois', value: 'IL'},
    { label : 'Indiana', value: 'IN'},
    { label : 'Iowa', value: 'IA'},
    { label : 'Kansas', value: 'KS'},
    { label : 'Kentucky', value: 'KY'},
    { label : 'Louisiana', value: 'LA'},
    { label : 'Maine', value: 'ME'},
    { label : 'Marshall Islands', value: 'MH'},
    { label : 'Maryland', value: 'MD'},
    { label : 'Massachusetts', value: 'MA'},
    { label : 'Michigan', value: 'MI'},
    { label : 'Minnesota', value: 'MN'},
    { label : 'Mississippi', value: 'MS'},
    { label : 'Missouri', value: 'MO'},
    { label : 'Montana', value: 'MT'},
    { label : 'Nebraska', value: 'NE'},
    { label : 'Nevada', value: 'NV'},
    { label : 'New Hampshire', value: 'NH'},
    { label : 'New Jersey', value: 'NJ'},
    { label : 'New Mexico', value: 'NM'},
    { label : 'New York', value: 'NY'},
    { label : 'North Carolina', value: 'NC'},
    { label : 'North Dakota', value: 'ND'},
    { label : 'Northern Mariana Islands', value: 'MP'},
    { label : 'Ohio', value: 'OH'},
    { label : 'Oklahoma', value: 'OK'},
    { label : 'Oregon', value: 'OR'},
    { label : 'Palau', value: 'PW'},
    { label : 'Pennsylvania', value: 'PA'},
    { label : 'Puerto Rico', value: 'PR'},
    { label : 'Rhode Island', value: 'RI'},
    { label : 'South Carolina', value: 'SC'},
    { label : 'South Dakota', value: 'SD'},
    { label : 'Tennessee', value: 'TN'},
    { label : 'Texas', value: 'TX'},
    { label : 'Utah', value: 'UT'},
    { label : 'Vermont', value: 'VT'},
    { label : 'Virgin Islands', value: 'VI'},
    { label : 'Virginia', value: 'VA'},
    { label : 'Washington', value: 'WA'},
    { label : 'West Virginia', value: 'WV'},
    { label : 'Wisconsin', value: 'WI'},
    { label : 'Wyoming', value: 'WY' }
]

const toastConfig = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}

module.exports = {
  sessionStatuses,
  deviceStatuses,
  orderStatuses,
  scanStatuses,
  roles,
  US_states,
  toastConfig,
};
