/*eslint-disable*/
import React, { Component } from "react";
import Collapse from "react-bootstrap/lib/Collapse";
import { NavLink } from "react-router-dom";
import vendorService from "services/vendorService.jsx";
import userService from "services/userService.jsx";

// this is used to create scrollbars on windows devices like the ones from apple devices
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.jsx";
// logo for sidebar
import logo from "assets/brand/logo.svg";
import logo_mini from "assets/brand/Icon.png";
import routes from "routes.js";
import { can_navigate } from '../Can.jsx';
import { authContext } from 'state/AuthProvider';


var ps;

class Sidebar extends Component {
  static contextType = authContext;
  constructor(props) {
    super(props);
    this.state = {
      ...this.getCollapseStates(routes),
      openAvatar: false,
      isWindows: navigator.platform.indexOf("Win") > -1 ? true : false,
      width: window.innerWidth,
      user: [],
    };
  }
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.hidden === true) {
        return null;
      }
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.hidden) {
        return null;
      }
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        return (can_navigate(prop.name) &&
          <li
            className={this.getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}
          >
            <a
              href="#pablo"
              onClick={(e) => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              <i className={prop.icon} />
              <p>
                {prop.name}
                <b
                  className={
                    this.state[prop.state] ? "caret rotate-180" : "caret"
                  }
                />
              </p>
            </a>
            <Collapse in={this.state[prop.state]}>
              <ul className="nav">{this.createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }
      return (
        <li className={this.activeRoute(prop.layout + prop.path)} key={key}>
          {can_navigate(prop.name) ?
            ((prop.name === "Vendor" && this.state.user) ? <NavLink
              to={prop.layout + prop.path.split(":vendorId").join(this.state.user.vendorId)}
              className="nav-link"
              activeClassName="active"
            >
              {prop.icon ?
                <>
                  <i className={prop.icon} />
                  <p>{this.state.user.vendor_name}</p>
                </> :
                <>
                  <span className="sidebar-mini">{prop.mini}</span>
                  <span className="sidebar-normal">{prop.name}</span>
                </>
              }
            </NavLink> :
              <NavLink
                to={prop.layout + prop.path}
                className="nav-link"
                activeClassName="active"
              >
                {prop.icon ?
                  <>
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </> :
                  <>
                    <span className="sidebar-mini">{prop.mini}</span>
                    <span className="sidebar-normal">{prop.name}</span>
                  </>
                }
              </NavLink>) : null}
        </li >
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // if the windows width changes CSS has to make some changes
  // this functions tell react what width is the window
  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }
  async vendor() {
    let auth = this.context;
    if (auth.auth.role !== "Super Admin") {
      let res = await userService.getCurrentUser();
      if (res.success === true) {
        this.setState({ user: res.user });
      }
      await vendorService.vendor(this.state.user.vendorId).then((res) =>
        this.setState(prevState => ({
          user: {
            ...prevState.user,
            vendor_name: res.vendor.name
          }
        }))
      ).catch((e) => console.log(e));
    }

  }
  componentDidUpdate() {
    if (navigator.platform.indexOf("Win") > -1) {
      setTimeout(() => {
        ps.update();
      }, 350);
    }
  }
  componentDidMount() {
    this.updateDimensions();
    this.vendor();
    // if (auth.role !== "Super Admin") { this.vendor(); }
    // add event listener for windows resize
    window.addEventListener("resize", this.updateDimensions.bind(this));
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }
  componentWillUnmount() {
    // we need to destroy the false scrollbar when we navigate
    // to a page that doesn't have this component rendered
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  render() {
    return (
      <div
        className="sidebar"
        data-color="azure"
      //data-image={this.props.image}
      >
        {this.props.hasImage ? (
          <div
            className="sidebar-background"
          //style={{ backgroundImage: "url(" + this.props.image + ")" }}
          />
        ) : (
            ""
          )}
        <div className="sidebar-wrapper" ref="sidebarWrapper">
          <div className="logo">
            <a href="#" className="simple-text logo-mini" target="_blank">
              <img src={logo} alt="react-logo" className="logo-main" />
              <img src={logo_mini} alt="react-logo" className="logo-mini" />
            </a>
          </div>
          {/*<div className="user">
            <div className="photo">
              <img src={avatar} alt="Avatar" />
            </div>
            <div className="info">
              <a
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ openAvatar: !this.state.openAvatar });
                }}
              >
                <span>
                  Tania Andrew
                  <b
                    className={
                      this.state.openAvatar ? "caret rotate-180" : "caret"
                    }
                  />
                </span>
              </a>
              <Collapse in={this.state.openAvatar}>
                <ul className="nav">
                  <li>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <span className="sidebar-mini">MP</span>
                      <span className="sidebar-normal">My Profile</span>
                    </a>
                  </li>
                  <li>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <span className="sidebar-mini">EP</span>
                      <span className="sidebar-normal">Edit Profile</span>
                    </a>
                  </li>
                  <li>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <span className="sidebar-mini">S</span>
                      <span className="sidebar-normal">Settings</span>
                    </a>
                  </li>
                </ul>
              </Collapse>
            </div>
          </div>*/}
          <ul className="nav">
            {/*
              If we are on responsive, we want both links from navbar and sidebar
              to appear in sidebar, so we render here HeaderLinks
            */}
            {this.state.width <= 992 ? <AdminNavbarLinks /> : null}
            {/*
              here we render the links in the sidebar if the link is simple,
              we make a simple link, if not, we have to create a collapsible group,
              with the speciffic parent button and with it's children which are the links
            */}
            {this.createLinks(routes)}
          </ul>
        </div>
      </div>

    );
  }
}

export default Sidebar;
