import React from "react";
import FormGroup from "react-bootstrap/lib/FormGroup";
import ControlLabel from "react-bootstrap/lib/ControlLabel";
import FormControl from "react-bootstrap/lib/FormControl";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";

import Card from "components/Card/Card.jsx";
import FormInputs from "components/FormInputs/FormInputs.jsx";
import { validate } from 'indicative/validator';
import Button from "components/CustomButton/CustomButton.jsx";
import userService from 'services/userService';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import { useEffect } from "react";
import { useHistory } from "react-router";
import { US_states } from "../../variables/Variables.jsx";
import Select from 'react-select';
import clean from 'utils';

function UserPage(props) {
  const [creds, setCreds] = React.useState({
    id: "",
    name: "",
    email: "",
    phoneNumber: "",
    dob: "",
    address1: "",
    address2: "",
    city: "",
    zipcode: "",
    state: "",
    role: "",
    vendor: "",
  }

  );
  const history = useHistory();
  const [msg, setMsg] = React.useState({ "success": "", "invalid": "", "buttonDisabled": true });
  const [validatorMessage, setValidatorMessage] = React.useState(null);

  useEffect(() => {
    const id = props.match.params.userId;

    userService.getUser(id).then((res) => {
      if (res.success === true) {
        // TODO
        let date = new Date(res.user.dob);
        if (res.user.address === undefined || res.user.address === null) {
          res.user.address = {
            address1: "",
            address2: "",
            city: "",
            zipcode: "",
            state: "",
            role: "",
          }
        }

        setCreds({
          ...creds,
          id: id,
          name: res.user.name,
          email: res.user.email,
          phoneNumber: res.user.phoneNumber,
          dob: date.toISOString().slice(0, 10),
          role: res.user.role,
          address1: res.user.address.address1,
          address2: res.user.address.address2,
          city: res.user.address.city,
          zipcode: res.user.address.zipcode,
          state: res.user.address.state,
          vendor: res.user.vendor.name,
        });
      } else {
        setMsg({ ...msg, success: "", invalid: "Couldn't load user data" });
        setValidatorMessage("");
      }
    });
  }, []);

  const handleChange = e => {
    setCreds({
      ...creds,
      [e.target.name]: e.target.value,
    });
  };

  const handleRoleChange = (e) => {
    setCreds({ ...creds, role: e.target.value });
  };
  const handlePhoneChange = e => {
    setCreds({ ...creds, phoneNumber: e });
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    const rules = {
      name: 'required',
      phoneNumber: 'required',
      dob: 'required'
    };
    const messages = {
      required: (field) => `${field.charAt(0).toUpperCase() + field.slice(1).replace(/([a-z])([A-Z-_])/g, '$1 $2')} is required`,
    };
    const error = [{ message: "Please enter a valid phone number" }];
    try {
      const resp = await validate(creds, rules, messages);
      const isValidNumber = isPossiblePhoneNumber(creds.phoneNumber);
      if (!isValidNumber) {
        throw error;
      }
      let params = {
        name: creds.name,
        phone_number: creds.phoneNumber,
        dob: creds.dob,
        role: creds.role,
        address: {
          city: creds.city,
          address1: creds.address1,
          address2: creds.address2,
          city: creds.city,
          zipcode: creds.zipcode,
          state: creds.state,
        }
      };

      clean(params);

      let res = await userService.updateUser(props.match.params.userId, params);
      if (res.success === true) {
        setValidatorMessage(null);
        setMsg({ ...msg, success: "Profile Updated Successfully!", invalid: "" });
        setTimeout(() => history.push("/admin/users"), 3000);
      }
      else {
        setValidatorMessage(null);
        setMsg({ ...msg, success: "", invalid: "Profile was not updated!" });
      }
    } catch (e) {
      console.log(e);
      setMsg({ ...msg, invalid: "", success: "" });
      setValidatorMessage(e[0].message);
    }

  };

  return (
    <div className="main-content" >
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title="Edit User Profile"
              content={
                <form>
                  <div>
                    {validatorMessage && (
                      <div
                        className="alert alert-danger mt-2"
                        style={{
                          display: validatorMessage ? 'block' : 'none',
                        }}
                        role="alert"
                      >
                        {' '}
                        {validatorMessage}
                      </div>
                    )}
                  </div>
                  <div>
                    {msg.invalid && (
                      <div
                        className="alert alert-danger mt-2"
                        style={{
                          display: msg.invalid ? 'block' : 'none',
                        }}
                        role="alert"
                      >
                        {' '}
                        {msg.invalid}
                      </div>
                    )}
                  </div>
                  <div>
                    {msg.success && (
                      <div
                        className="alert alert-success mt-2"
                        style={{
                          display: msg.success ? 'block' : 'none',
                        }}
                        role="alert"
                      >
                        {' '}
                        {msg.success}
                      </div>
                    )}
                  </div>
                  <FormInputs
                    ncols={["col-md-6", "col-md-6"]}
                    proprieties={[
                      {
                        label: "Company",
                        name: "company",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Company",
                        defaultValue: creds.vendor,
                        disabled: true,
                      },
                      {
                        label: "Email address",
                        name: "email",
                        type: "email",
                        bsClass: "form-control",
                        defaultValue: creds.email,
                        disabled: true,
                      },
                    ]}
                  />
                  <div className="row">
                    <div className="col-md-6">
                      <FormGroup controlId="formControlsSelect">
                        <ControlLabel>Role</ControlLabel>
                        <FormControl componentClass="select" onChange={handleRoleChange} value={creds.role} placeholder="Role">
                          <option value="VendorAdmin">Admin</option>
                          <option value="VendorAssociate">Associate</option>
                        </FormControl>
                      </FormGroup>
                    </div>
                  </div>
                  <FormInputs
                    ncols={["col-md-6", "col-md-6"]}
                    proprieties={[
                      {
                        label: "Name",
                        type: "text",
                        name: "name",
                        bsClass: "form-control",
                        placeholder: "Name",
                        value: creds.name,
                        onChange: (e) => handleChange(e)
                      },
                      {
                        label: "Date of Birth",
                        type: "date",
                        name: "dob",
                        bsClass: "form-control",
                        value: creds.dob,
                        onChange: (e) => handleChange(e)
                      },
                    ]}
                  />
                  <FormInputs
                    ncols={["col-md-4"]}
                    proprieties={[
                      {
                        label: "Phone Number",
                        componentClass: PhoneInput,
                        name: "phoneNumber",
                        value: creds.phoneNumber,
                        onChange: (e) => handlePhoneChange(e)
                      },
                    ]}
                  />
                  <FormInputs
                    ncols={["col-md-10"]}
                    proprieties={[
                      {
                        label: "Address 1",
                        type: "text",
                        name: "address1",
                        bsClass: "form-control",
                        value: creds.address1,
                        onChange: (e) => handleChange(e)
                      },
                    ]}
                  />
                  <FormInputs
                    ncols={["col-md-10"]}
                    proprieties={[
                      {
                        label: "Address 2",
                        type: "text",
                        name: "address2",
                        bsClass: "form-control",
                        value: creds.address2,
                        onChange: (e) => handleChange(e)
                      },
                    ]}
                  />
                  <FormInputs
                    ncols={["col-md-6", "col-md-6"]}
                    proprieties={[
                      {
                        label: "City",
                        type: "text",
                        name: "city",
                        bsClass: "form-control",
                        placeholder: "City",
                        value: creds.city,
                        onChange: (e) => handleChange(e)
                      },
                      {
                        label: "Zipcode",
                        type: "text",
                        name: "zipcode",
                        bsClass: "form-control",
                        placeholder: "Zipcode",
                        value: creds.zipcode,
                        onChange: (e) => handleChange(e)
                      },
                    ]}>
                  </FormInputs>
                  <Row>
                    <FormGroup className="col-lg-6">
                      <ControlLabel>STATE</ControlLabel>
                      <Select
                        placeholder="State"
                        name="state"
                        value={US_states.filter(option => option.value === creds.state)}
                        className="select"
                        options={US_states}
                        onChange={(state) => {
                          setCreds({ ...creds, state: state.value })
                        }}
                      />
                    </FormGroup>
                  </Row>

                  <Button bsStyle="primary" pullRight fill type="submit" onClick={handleSubmit}>
                    Update Profile
                    </Button>
                  <div className="clearfix" />
                </form>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div >
  );
}


export default UserPage;
