import React, { useEffect, useState } from "react";
import debounce from 'components/Debounce';
// react component for creating dynamic tables
import Table from "components/Table/Table.jsx";
import { useHistory } from 'react-router';
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import Modal from "react-bootstrap/lib/Modal";
import ColumnFilter from "components/Table/ColumnFilter.jsx";

import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import orderService from 'services/orderService';
import vendorService from 'services/vendorService';
import SelectColumnFilter from "components/Table/SelectColumnFilter.jsx";
import { orderStatuses } from '../variables/Variables.jsx';
import Can from "../components/Can.jsx";

function Orders(props) {
  const history = useHistory();
  const [data, setData] = React.useState([]);
  const [loading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const [show, setShow] = useState(false);
  const [item, setItem] = useState({});
  const [vendors, setVendors] = React.useState([]);

  const [selectData, setSelectData] = React.useState({
    statuses: orderStatuses,
    vendors,
  });
  const handleClose = () => setShow(false);

  function fetchOrders({ pageSize, pageIndex, sortBy, filters }) {
    orderService.getOrders(pageSize, pageIndex, sortBy, filters).then((res) => {
      if (res.success) {
        let dataTable = res.orders;
        setData(
          dataTable.map((prop) => {
            return {
              id: prop.orderNumber,
              sessionId: prop.sessionId,
              amount: prop.amount,
              status: prop.status,
              orderNumber: prop.orderNumber,
              expiry: prop.expiry !== null ? (new Date(prop.expiry).toISOString().slice(0, 10)) : "-",
              currency: prop.currency,
              vendor: prop.vendor ? prop.vendor.name : "Unassigned",
              user: prop.user.name,
              actions: (
                // we've added some custom button actions
                <div className="actions-right">
                  <Button
                    onClick={(e) => {
                      let obj = dataTable.find((o) => o.orderNumber === prop.orderNumber);
                      setItem(obj);
                      setShow(true);
                      history.push(`/admin/order/${obj.orderNumber}`);
                    }}
                    bsStyle="primary"
                    simple
                    icon
                  >
                    <i className="fa fa-eye" />
                  </Button>{" "}
                </div>
              ),
            };
          }),
        )
        setTotalCount(res.count);
        setPageCount(Math.ceil(res.count / pageSize));
      }
    })
  };

  let clmns = [
    {
      Header: "Order Number",
      accessor: "orderNumber",
      Filter: ColumnFilter,
    },
    {
      Header: "User",
      accessor: "user",
      Filter: ColumnFilter,
    },
    {
      Header: "Status",
      accessor: "status",
      Filter: SelectColumnFilter,
    },
    {
      Header: "Amount",
      accessor: "amount",
      Filter: ColumnFilter,
    },
    {
      Header: "Vendor",
      accessor: "vendor",
      disableSortBy: true,
      Filter: SelectColumnFilter,
    },
    {
      Header: "Expiry Date",
      accessor: "expiry",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: "Actions",
      accessor: "actions",
      disableSortBy: true,
      disableFilters: true,
    },
  ];
  if (Can("orders-page:create")) {
    clmns = clmns.filter(row => {
      return row.accessor !== "vendor";
    })
  }
  const columns = React.useMemo(
    () =>
      clmns,
    []
  )
  useEffect(() => {
    //fetchOrders({});
    //fetchData({});
    if (!Can("orders-page:create")) {
      vendorService.vendors().then((res) => {
        if (res.success) {
          setVendors(res.vendors);
          setSelectData({
            ...selectData,
            vendors: res.vendors,
          });
        }
      })
    }
  }, []);

  const fetchData = React.useCallback(debounce(fetchOrders, 500), []);

  return (
    <div className="main-content">
      <Grid fluid>
        <Row className="ds-table-row">
          <Col md={12}>
            <Card
              title="Orders"
              content={
                <Table
                  columns={columns}
                  data={data}
                  fetchData={fetchData}
                  loading={loading}
                  pageCount={pageCount}
                  totalCount={totalCount}
                  selectData={selectData}
                />
              }
            />
          </Col>
        </Row>
      </Grid>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={3}>
              <label>Order ID: </label>
            </Col>
            <Col md={9}>
              <span>{item.id}</span>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <label>Status: </label>
            </Col>
            <Col md={9}>
              <span>{item.status}</span>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <label>Session ID: </label>
            </Col>
            <Col md={9}>
              <span>{item.sessionId}</span>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <label>Amount: </label>
            </Col>
            <Col md={9}>
              <span>{item.amount}</span>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <label>Currency: </label>
            </Col>
            <Col md={9}>
              <span>{item.currency}</span>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="default" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div >
  );
}

export default Orders;
