import React, { useState, useEffect } from "react";
import debounce from 'components/Debounce';
// react component for creating dynamic tables
import Table from "components/Table/Table.jsx";
import Modal from "react-bootstrap/lib/Modal";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom'
import sessionService from 'services/sessionService';
import { sessionStatuses } from '../variables/Variables.jsx';
import SelectColumnFilter from "components/Table/SelectColumnFilter.jsx";
import userService from 'services/userService';
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

function Sessions(props) {

  const history = useHistory();
  const [show, setShow] = useState(false);
  const [loading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const [associates, setAssociates] = React.useState([]);
  const [selectData, setSelectData] = React.useState({
    statuses: sessionStatuses,
    associates,
  });

  const handleClose = () => setShow(false);
  const location = useLocation();
  const [data, setData] = React.useState([]);

  const columns = React.useMemo(() => [
    {
      Header: "Patient Name",
      accessor: "patientName",
    },
    {
      Header: "Assigned To",
      accessor: "assignedTo",
      Filter: SelectColumnFilter,
    },
    {
      Header: "Status",
      accessor: "status",
      Filter: SelectColumnFilter,
      disableFilters: location.pathname.includes("active-sessions") ? false : true,
    },
    {
      Header: "Actions",
      accessor: "actions",
      disableSortBy: true,
      disableFilters: true,
    },
  ])

  function fetchSessions({ pageSize, pageIndex, sortBy, filters }) {
    sessionService.getSessions(location.pathname.includes('active-sessions'),
      pageSize, pageIndex, sortBy, filters).then((res) => {
        if (res.success) {
          let dataTable = res.sessions;
          setData(
            dataTable.map((prop) => {
              return {
                id: prop.id,
                patientName: prop.profile.name,
                assignedTo: (prop.assignedTo === null) ? "UNASSIGNED" : prop.assignedTo.name,
                status: prop.status,
                actions: (
                  <div className="actions-right">
                    <Button
                      onClick={(e) => {
                        let obj = dataTable.find((o) => o.id === prop.id);
                        history.push(`/admin/session/${obj.id}`);
                      }}
                      bsStyle="primary"
                      simple
                      icon
                    >
                      <i className="fa fa-eye" />
                    </Button>{" "}
                  </div>
                ),
              };
            }),
          );
          setPageCount(Math.ceil(res.count / pageSize));
          setTotalCount(res.count);
        }
      });
  }
  useEffect(() => {
    //fetchSessions({});
    userService.getVendorAssociates().then((res) => {
      if (res.success) {
        setAssociates(res.users);
        setSelectData({
          ...selectData,
          associates: res.users,
        });
      }
    })
  }, []);

  const fetchData = React.useCallback(debounce(fetchSessions, 500), []);

  return (
    <div className="main-content">
      <Grid fluid>
        <Row className="ds-table-row">
          <Col md={12}>
            <Card
              title={props.name}
              content={
                <Table
                  columns={columns}
                  data={data}
                  fetchData={fetchData}
                  loading={loading}
                  pageCount={pageCount}
                  totalCount={totalCount}
                  selectData={selectData}
                />
              }
            />
          </Col>
        </Row>
      </Grid>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>User Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={3}>
              <label>Name: </label>
            </Col>
            <Col md={9}>
              <span>Tiger Nixon</span>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <label>Email: </label>
            </Col>
            <Col md={9}>
              <span>tiger.nixon@dentskan.comn</span>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <label>Role: </label>
            </Col>
            <Col md={9}>
              <span>Admin</span>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <label>Phone Number: </label>
            </Col>
            <Col md={9}>
              <span>-541-754-3010</span>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Sessions;
