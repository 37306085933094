/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { render } from "react-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "assets/sass/light-bootstrap-dashboard-pro-react.scss?v=1.2.0";
import "assets/css/demo.css";
import "assets/css/pe-icon-7-stroke.css";
import "assets/css/phone-input-style.css";
import 'react-toastify/dist/ReactToastify.min.css';

import { AuthProvider } from "state/AuthProvider.jsx";

import Routing from "routing.jsx";

const App = () => (
  <>
    <AuthProvider>
      <Routing />
    </AuthProvider>
  </>
);

render(<App />, document.getElementById("root"));
