const rules = {
  "Vendor Associate": {
    pages: [
      "Vendor",
      "Sessions",
      "Orders",
      "Active Sessions",
      "Completed Sessions",
      "My Details"
    ],
    static: [
      "orders-page:create",
      "orders-page:edit",
      "orders-page:view"
    ]
  },

  "Vendor Admin": {
    pages: [
      "Vendor",
      "Users",
      "Accesories",
      "Orders",
      "Sessions",
      "Active Sessions",
      "Completed Sessions",
      "My Details"
    ],
    static: [
      "johnvendor-page:view",
      "users-page:create",
      "users-page:edit",
      "vendors-page:self-edit",
      "orders-page:create",
      "orders-page:edit",
      "sessions-page:create",
      "sessions-page:edit",
    ]
  },

  "Super Admin": {
    pages: [
      "Users",
      "Accesories",
      "Orders",
      "Vendors",
      "My Details"
    ],
    static: [
      "users-page:create",
      "users-page:admin",
      "users-page:edit",
      "accessories-page:create",
      "accessories-page:delete",
      "vendors-page:edit",
      "vendors-page:create",
      "admin-page:edit",
    ]
  }
};

export default rules;
